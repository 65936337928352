import CompanyInfo from "./CompanyInfo";
import CustomerInfo from "./CustomerInfo";
import FooterInfo from "./FooterInfo";
import PdfTable from "./PdfTable";

const ParentPdf = ({
  footerShow,
  title,
  data,
  pageNumber,
  tableHeader,
  tableData,
  doFooterData,
  quotationFooterData,
  invoiceFooterData,
  acceptedBy,
  bankInfo,
  showBankInfo,
  showTermsCondition,
  customerInfoFor,
  totalPages,
  pageIndex,
}) => {
  return (
    <div className="print-container h-full w-full">
      <div className="page-content">
        {/* -----header section------ */}
        <CompanyInfo title={title} />
        <CustomerInfo
          pageNumber={pageNumber}
          customerInfo={data}
          customerInfoFor={customerInfoFor}
        />

        {/* -----table section------ */}
        <div className="mt-5">
          <PdfTable
            footerShow={footerShow}
            columns={tableHeader}
            data={tableData}
            doFooterData={doFooterData}
            quotationFooterData={quotationFooterData}
            invoiceFooterData={invoiceFooterData}
            totalPages={totalPages}
            pageIndex={pageIndex}
          />
        </div>

        {/* -----pdf footer section------ */}
        <FooterInfo
          acceptedBy={acceptedBy}
          bankInfo={bankInfo}
          showBankInfo={showBankInfo}
          showTermsCondition={showTermsCondition}
        />
      </div>
    </div>
  );
};

export default ParentPdf;
