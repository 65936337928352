import React, { useEffect, useRef, useState } from "react";
import ProductList from "../Quotition/ProductList";
import Modal from "../Shared/Modal";
import { AiFillPlusCircle } from "react-icons/ai";
import axios from "axios";
import Alert from "../Shared/Alert";
import { useHeader } from "../../lib/headers";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BiEdit } from "react-icons/bi";
import { AiOutlineEye } from "react-icons/ai";
import { AiFillDelete } from "react-icons/ai";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import PrintInvoice from "./PrintInvoice";
import NewPrintInvoice from "./NewPrintInvoice";
// Set today's date in YYYY-MM-DD format
const today = new Date().toISOString().split("T")[0];
const initialPaymentState = {
  paymentMethod: "bank",
  date: today,
  description: "full",
  transaction: "",
  amount: "",
};
const ViewInvoice = () => {
  const [showModal, setShowModal] = useState(false);

  const [projectData, setprojectData] = useState([]);

  const [searchTitle, setSearchTitle] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [invoice, setInvoice] = useState([]);

  const [singleData, setSingleData] = useState({});
  const componentRef = useRef();
  const newPdfRef = useRef();
  const navigate = useNavigate();
  const { id } = useParams();
  //setting token
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  //payment states ---------------------------------------
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [paymentState, setPaymentState] = useState(initialPaymentState);
  const [showReasonOfCancel, setShowReasonOfCancel] = useState(false);
  const [reasonOfCancel, setReasonOfCancel] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ROOT_URL}/api/invoice/${id}`,
          { headers }
        );

        setSingleData(response?.data);
        setSelectedRow(response?.data?.product);
        setInvoice(response?.data?.product);
        //set payment system default value
        setPaymentState((prev) => {
          return {
            ...prev,
            amount: response?.data?.grand_total?.toFixed(2),
          };
        });
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [id]);

  // Function to fetch data from the API
  const getProjectList = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ROOT_URL}/api/project/`,
        { headers }
      );
      setprojectData(response?.data?.results);
    } catch (error) {
      console.error(error);
    }
  };

  const getSearchData = async () => {
    try {
      // const params = new URLSearchParams({ q: searchTitle });
      const response = await axios.get(
        `${process.env.REACT_APP_ROOT_URL}/api/product/?search=${searchTitle}`,
        { headers }
      );

      setSearchData(response?.data?.results);
    } catch (error) {}
  };

  // fetch search data
  useEffect(() => {
    getSearchData();
  }, [searchTitle]);

  useEffect(() => {
    getProjectList();
  }, []);

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showModal]);

  const handleStatus = async () => {
    try {
      const data = {
        status: 2,
      };
      await axios
        .patch(
          `${process.env.REACT_APP_ROOT_URL}/api/invoice/${singleData?.id}/`,
          data,
          { headers }
        )
        .then((res) => {
          Alert("Status Updated", "success");
          setSingleData(res?.data);
          setSelectedRow(res?.data?.product);
          setInvoice(res?.data?.product);
        });
    } catch (error) {}
  };

  function calculateTotalPriceAfterDiscount(isItemDiscount, item) {
    const initialTotal =
      Number(
        item?.category_name === "Ducting" ? item?.unit_price : item?.sell_price
      ) * Number(item?.new_quantity);

    if (isItemDiscount && item?.item_discount) {
      const discountPercentage = Number(item.item_discount);

      const discountAmount = (initialTotal * discountPercentage) / 100;

      const totalAfterPercentageDiscount = initialTotal - discountAmount;
      return totalAfterPercentageDiscount?.toFixed(2);
      // if (isFlatDiscount) {
      //   const discountAmount = Number(item.item_discount);
    } else {
      return initialTotal.toFixed(2);
    }
  }

  const handlePaymentChange = (e) => {
    setPaymentState((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  //   =============================== handle payment submission ===============
  const handlePaymentSubmit = async () => {
    console.log("paymentState", paymentState);
    const data = {
      invoice: id,
      payment_method: paymentState.paymentMethod,
      payment_date: paymentState.date,
      transaction_number: paymentState.transaction,
      payment_mode: paymentState.description,
      payment_amount: paymentState.amount,
    };
    console.log("invoice id: ", id);

    await axios
      .post(`${process.env.REACT_APP_ROOT_URL}/api/payment/`, data, {
        headers,
      })
      .then((response) => {
        Alert("Invoice added successfully!", "success");
        window.location.reload();
        // navigate("/invoice");
        // Handle the response data
      })
      .catch((error) => {
        // Handle any errors
        console.error(error);
      });
  };
  const handleInvoiceCancel = async () => {
    const data = {
      invoice: id,
      cancel_reason: reasonOfCancel,
      status: 3,
    };
    await axios
      .patch(
        `${process.env.REACT_APP_ROOT_URL}/api/invoice/${singleData?.id}/`,
        data,
        {
          headers,
        }
      )
      .then((response) => {
        Alert("Invoice cancelled successfully!", "danger");
        window.location.reload();
      })
      .catch((error) => {
        console.error(error);
      });
  };
  return (
    <>
      <div className=" ">
        <div className="hidden">
          <PrintInvoice
            componentRef={componentRef}
            quotaionData={singleData}
            items={selectedRow}
          />
        </div>
        <div className="hidden">
          <NewPrintInvoice
            componentRef={newPdfRef}
            quotaionData={singleData}
            items={selectedRow}
          />
        </div>

        <div className=" flex justify-between items-center">
          <button className=" my-2 bg-gray-900 text-white px-4 py-2 rounded-lg mr-[2%] ">
            <Link to={`/invoice`}>Back</Link>
          </button>
          <ReactToPrint
            trigger={() => (
              <button className=" my-2 bg-gray-900 text-white px-4 py-2 rounded-lg mr-[2%] ">
                Print
              </button>
            )}
            content={() => componentRef.current}
            documentTitle={singleData?.ref}
          />
          <ReactToPrint
            trigger={() => (
              <button className=" my-2 bg-gray-900 text-white px-4 py-2 rounded-lg mr-[2%] ">
                New Print
              </button>
            )}
            content={() => newPdfRef.current}
            documentTitle={singleData?.ref}
          />
        </div>

        <div className="px-4">
          <h1 className="  text-3xl my-4 text-gray-950 font-bold text-center ">
            View Invoice
          </h1>
          <div className=" flex justify-between items-center">
            <div>
              <h2 id="summary-heading" class="pb-0 text-xl  text-gray-900">
                <>
                  <span className=" font-medium"> Company:</span>{" "}
                  <span>{singleData?.company_name} </span>
                </>
              </h2>
              <div class="flex items-center">
                <dt class="text-sm font-medium text-gray-800">
                  Company Address:
                </dt>
                <dd class=" ml-2 text-sm ">{singleData?.company_address}</dd>
              </div>

              <div class="flex items-center">
                <dt class="text-sm font-medium text-gray-800">Attention:</dt>
                <dd class=" ml-2 text-sm ">{singleData?.attention}</dd>
              </div>

              <div class="flex items-center">
                <dt class="text-sm font-medium text-gray-800">
                  Project name & Address:
                </dt>
                <dd class=" ml-2 text-sm ">
                  {singleData?.site_address?.address}
                </dd>
              </div>
            </div>

            <div className=" ">
              <div class="flex items-center">
                <dt class="text-sm font-medium text-gray-800">NO:</dt>
                <dd class=" ml-2 text-sm ">{singleData?.ref}</dd>
              </div>

              <div class="flex items-center">
                <dt class="text-sm font-medium text-gray-800">Date:</dt>
                <dd class=" ml-2 text-sm ">{singleData?.date}</dd>
              </div>

              <div class="flex items-center">
                <dt class="text-sm font-medium text-gray-800">P.O/QT No:</dt>
                <dd class=" ml-2 text-sm ">{singleData?.qt_number}</dd>
              </div>
              <div class="flex items-center">
                <dt class="text-sm font-medium text-gray-800">DO No:</dt>
                <dd class=" ml-2 text-sm ">{singleData?.do_no}</dd>
              </div>

              <div class="flex items-center">
                <dt class="text-sm font-medium text-gray-800">Order No:</dt>
                <dd class=" ml-2 text-sm ">{singleData?.order_number}</dd>
              </div>

              <div class="flex items-center">
                <dt class="text-sm font-medium text-gray-800">Status:</dt>
                <dd class=" ml-2 text-sm ">{singleData?.status}</dd>
              </div>

              <div class="flex items-center">
                <dt class="text-sm font-medium text-gray-800">Terms:</dt>
                <dd class=" ml-2 text-sm ">{singleData?.terms}</dd>
              </div>

              <div class="flex items-center">
                <dt class="text-sm font-medium text-gray-800">Sales Person:</dt>
                <dd class=" ml-2 text-sm ">{singleData?.sales_person}</dd>
              </div>
            </div>
          </div>
          {/* <div className=" flex justify-between items-center">
            <h2
              id="summary-heading"
              class=" px-4 py-3 text-xl  text-gray-900 sm:p-4"
            >
              {singleData?.company_name && (
                <>
                  <span className=" font-medium"> Company:</span>{" "}
                  <span>{singleData?.company_name} </span>
                </>
              )}
            </h2>
            <div className=" ">
              <div class=" space-y-1 px-2 py-4">
                <div class="flex items-center justify-between">
                  <dt class="text-sm font-medium text-gray-800">Date:</dt>
                  <dd class=" ml-2 text-sm ">{singleData?.date}</dd>
                </div>
                <div class="flex items-center justify-between mt-2">
                  <dt class="text-sm font-medium text-gray-800">Status: </dt>
                  <dd class=" ml-2 text-sm  ">{singleData?.status}</dd>
                </div>
              </div>
            </div>
          </div> */}
          <div class=" mt-2  w-full">
            <section class="rounded-lg col-span-12 bg-white lg:col-span-8 w-full">
              <table class="w-full text-sm text-left text-gray-500 ">
                <thead class="text-xs text-gray-700 uppercase bg-gray-50 ">
                  <tr>
                    <th>S/N</th>
                    <th scope="col" class="px-6 py-3">
                      Product Description
                    </th>
                    <th scope="col" class="px-6 py-3  ">
                      Size
                    </th>
                    <th scope="col" class="px-6 py-3">
                      Unit Price
                    </th>
                    <th scope="col" class="px-6 py-3">
                      Quantity
                    </th>
                    {singleData?.is_item_discount && (
                      <th scope="col" class="px-6 py-3">
                        Discount
                      </th>
                    )}
                    <th scope="col" class="px-6 py-3">
                      Total
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {invoice?.map((item, index) => (
                    <tr className={` bg-white border-b `} key={item.id}>
                      <th className=" text-center">{index + 1}</th>
                      <th
                        scope="row"
                        className="px-6 py-1 font-medium text-gray-900 whitespace-nowrap "
                      >
                        <div className="text-dark text-xs flex">
                          {item?.category_name === "Ducting" && (
                            <span className=" space-x-1 uppercase">
                              <span className="ml-1">
                                {item?.ducting_type?.product_type}
                              </span>
                              <span>{item?.ducting_type?.thickness}</span>
                              <span>{item?.ducting_type?.brand_type}</span>

                              <span>{item?.ducting_type?.joint_type}</span>
                            </span>
                          )}
                          <span
                            className={`${
                              item?.category_name === "Ducting" && "ml-1"
                            }`}
                          >
                            {" "}
                            {item?.name}
                          </span>
                        </div>
                      </th>
                      <td className="px-6 py-4">
                        {" "}
                        <div className="text-xs flex items-center ">
                          {/* <span>{item?.description}</span> */}
                          <span>
                            {item?.category_name === "Ducting"
                              ? item?.display_dimensions
                              : item?.parent
                              ? Object.values(item?.variant || {}).join("/ ")
                              : item?.description || "\u00A0"}
                          </span>
                        </div>
                      </td>
                      <td className="px-6 py-4">
                        $
                        {item?.category_name === "Ducting"
                          ? Number(item?.unit_price)?.toFixed(3)
                          : Number(item?.sell_price)?.toFixed(3)}
                      </td>
                      <td className="px-6 py-4">
                        {item?.new_quantity} {item?.unit}
                      </td>
                      {singleData?.is_item_discount && (
                        <td className="px-6 py-4">
                          $
                          {Number(
                            item?.item_discount > 0 ? item?.item_discount : 0
                          )?.toFixed(2)}
                        </td>
                      )}
                      <td className="px-6 py-4">
                        $
                        {calculateTotalPriceAfterDiscount(
                          singleData?.is_item_discount,
                          item
                        )}
                        {/* {Number(
                          item?.category_name === "Ducting"
                            ? item?.unit_price * item.new_quantity
                            : Number(item.sell_price * item.new_quantity)
                        )?.toFixed(2)} */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </section>
            <section
              aria-labelledby="summary-heading"
              class="mt-5 rounded-md bg-white flex justify-end  mr-5"
            >
              <div>
                <div>
                  <div>
                    <dl class=" space-y-1 px-2 py-4">
                      <div class="flex items-center justify-between">
                        <dt class="text-sm text-gray-800">
                          Price ({singleData?.product?.length} item)
                        </dt>
                        <dd class="text-sm font-medium text-gray-900">
                          $ {singleData?.sub_total?.toFixed(2)}
                        </dd>
                      </div>
                      {singleData?.is_total_discount && (
                        <div class="flex items-center justify-between pt-4">
                          <dt class="flex items-center text-sm text-gray-800">
                            <span>Discount</span>
                          </dt>
                          <dd class="text-sm font-medium text-green-700">
                            $ {Number(singleData?.discount)?.toFixed(2)}
                          </dd>
                        </div>
                      )}

                      {singleData?.is_show_GST && (
                        <div class="flex items-center justify-between py-4">
                          <dt class="flex text-sm text-gray-800">
                            <span>GST</span>
                          </dt>
                          <dd class="text-sm font-medium text-green-700">
                            $ {singleData?.gst?.toFixed(2)}
                          </dd>
                        </div>
                      )}

                      <div class="flex items-center justify-between border-y border-dashed py-4 ">
                        <dt class="text-base font-medium text-gray-900">
                          Total Amount:
                        </dt>
                        <dd class="text-base font-medium text-gray-900">
                          $ {singleData?.grand_total?.toFixed(2)}
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
              </div>
            </section>
            {singleData?.payments && (
              <section className="pb-5">
                <div>
                  <h2 className="pb-0 text-xl  text-gray-900 text-center pt-5 pb-5">
                    Payments
                  </h2>
                  <table className="w-full text-sm text-left text-gray-500">
                    <thead>
                      <tr>
                        <th>Payment Date</th>
                        <th>Mode</th>
                        <th>Method</th>
                        <th>Transaction Number</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {singleData?.payments?.map((item, index) => (
                        <tr key={index}>
                          <td>{item?.payment_date}</td>
                          <td>{item?.payment_mode}</td>
                          <td>{item?.payment_method}</td>
                          <td>{item?.transaction_number}</td>
                          <td>{item?.payment_amount}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </section>
            )}
            <div className="flex">
              {singleData?.status === "Unpaid" && (
                <>
                  {/*<div>*/}
                  {/*  <button*/}
                  {/*      onClick={() => handleStatus(2)}*/}
                  {/*      className=" border px-4 py-2 rounded mx-2 bg-green-400 hover:bg-green-600 text-white  "*/}
                  {/*  >*/}
                  {/*    Paid*/}
                  {/*  </button>*/}
                  {/*</div>*/}
                  <button
                    onClick={() => setShowPaymentModal(true)}
                    className=" border px-4 py-2 rounded mx-2 bg-green-400 hover:bg-green-600 text-white  "
                  >
                    Payment
                  </button>
                  <button
                    onClick={() => setShowReasonOfCancel((prev) => !prev)}
                    className=" border px-4 py-2 rounded mx-2 bg-red-400 hover:bg-red-600 text-white  "
                  >
                    Cancel
                  </button>
                </>
              )}
            </div>
            {singleData?.status === "Cancelled" && (
              <>
                <h2 className="pb-0 text-md  text-red-900 text-center pt-5">
                  Cancel Reason
                </h2>
                <p className="pb-0 text-sm  text-red-900 text-center">
                  {singleData?.cancel_reason}
                </p>
              </>
            )}
          </div>
        </div>
        {/*-------------- payment modal section------------- */}
        {showPaymentModal && (
          <Modal title={"Payment"} setShowModal={setShowPaymentModal}>
            <div className=" grid grid-cols-1 gap-5 md:grid-cols-3 w-full mb-3 mt-4">
              <div className=" w-full mb-3">
                <label class="block mb-2 text-sm font-medium text-gray-900 ">
                  Payment Method
                </label>
                <select
                  name="paymentMethod"
                  value={paymentState.paymentMethod}
                  onChange={handlePaymentChange}
                  className="block w-full bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 p-2"
                >
                  {/* <option value="">-- Select an option --</option> */}
                  <option value="bank">Bank</option>
                  <option value="cash">Cash</option>
                </select>
              </div>
              <div className=" mt-2">
                <label class="block  text-sm font-medium text-gray-900 ">
                  Date
                </label>
                <input
                  name="date"
                  type="date"
                  value={paymentState.date}
                  onChange={handlePaymentChange}
                  className="block w-full bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 p-2"
                />
              </div>
              <div className=" w-full mb-3">
                <label class="block mb-2 text-sm font-medium text-gray-900 ">
                  Transaction / Reference Number
                </label>
                <input
                  name="transaction"
                  type="text"
                  value={paymentState.transaction}
                  onChange={handlePaymentChange}
                  className="block w-full bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 p-2"
                />
              </div>
            </div>
            <div className="grid grid-cols-1 gap-5 md:grid-cols-3 w-full mb-3 mt-2">
              <div className=" w-full mb-3">
                <label class="block mb-2 text-sm font-medium text-gray-900 ">
                  Description
                </label>
                <select
                  name="description"
                  value={paymentState.description}
                  onChange={handlePaymentChange}
                  className="block w-full bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 p-2"
                >
                  {/* <option value="">-- Select an option --</option> */}
                  <option value="full">Full</option>
                  <option value="partial">Partial</option>
                </select>
              </div>
              <div className=" w-full mb-3">
                <label class="block mb-2 text-sm font-medium text-gray-900 ">
                  Amount
                </label>
                <input
                  name="amount"
                  type="text"
                  value={paymentState.amount}
                  onChange={handlePaymentChange}
                  className="block w-full bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 p-2"
                />
              </div>
            </div>

            <div>
              <button
                onClick={handlePaymentSubmit}
                className=" border px-4 py-2 rounded bg-green-400 hover:bg-green-600 text-white  "
              >
                Submit
              </button>
            </div>
          </Modal>
        )}

        {showReasonOfCancel && (
          <Modal
            title={"Reason of Cancel"}
            setShowModal={setShowReasonOfCancel}
          >
            <div className="w-full mb-3 mt-2">
              <div className="w-full mb-3">
                <label class="block mb-2 text-sm font-medium text-gray-900 ">
                  Reason of cancel
                </label>
                <input
                  type="text"
                  value={reasonOfCancel}
                  onChange={(e) => setReasonOfCancel(e.target.value)}
                  className="block w-full bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 p-2"
                />
              </div>
            </div>
            <div>
              <button
                onClick={handleInvoiceCancel}
                className=" border px-4 py-2 rounded  bg-green-400 hover:bg-green-600 text-white mt-1  "
              >
                Send
              </button>
            </div>
          </Modal>
        )}
      </div>
    </>
  );
};

export default ViewInvoice;
