import React, { useEffect, useState } from "react";

import DOTable from "./DOTable";
import axios from "axios";
import { useHeader } from "../../lib/headers";
import Swal from "sweetalert2";

const DO = () => {
  const [deliveryOrder, setDeliveryOrder] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 25;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const headers = useHeader();
  const fetchDeliveryOrder = async () => {
    try {
      await axios
        .get(
          `${process.env.REACT_APP_ROOT_URL}/api/delivery_order/?page=${currentPage}&search=${searchText}`,
          headers
        )
        .then((response) => {
          setTotalItems(response.data?.count);
          setDeliveryOrder(response.data?.results);
        });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDeliveryOrder();
  }, [currentPage, searchText]);
  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(
            `${process.env.REACT_APP_ROOT_URL}/api/delivery_order/${id}/`,
            headers
          );
          // After successful deletion, refresh the data
          fetchDeliveryOrder();
          // Swal.fire("Deleted!", "Your item has been deleted.", "success");
        } catch (error) {
          console.error(error);
          Swal.fire(
            "Error!",
            "An error occurred while deleting the item.",
            "error"
          );
        }
      }
    });
  };
  return (
    <div>
      <DOTable
        handleDelete={handleDelete}
        deliveryOrder={deliveryOrder}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        itemsPerPage={itemsPerPage}
        totalItems={totalItems}
        setSearchText={setSearchText}
      />
    </div>
  );
};

export default DO;
