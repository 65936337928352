import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CiBoxList } from "react-icons/ci";
import axios from "axios";

const EditCompany = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  //   setting form data
  const [formData, setFormData] = useState({
    contact_person: "",
    name: "",
    phone: "",
    email: "",
    address: "",
  });

  //handle change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  //setting token for authorization
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  // submit function
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_ROOT_URL}/api/company/${id}/`,
        formData,
        { headers }
      );
      navigate("/company");
    } catch (error) {
      console.error(error);
    }
  };

  //   fetching default value
  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ROOT_URL}/api/company/${id}/`,
          { headers }
        );
        const apiData = response?.data;
        setFormData({
          name: apiData.name,
          email: apiData.email,
          contact_person: apiData.contact_person,
          address: apiData.address,
          phone: apiData.phone,
        });
      } catch (error) {
        console.error(error);
      }
    };

    fetchData(); // Call the fetchData function when the component mounts
  }, []);

  return (
    <div className=" flex justify-center items-center h-screen my-10 md:my-0">
      <form onSubmit={handleSubmit} class="w-full ">
        <div className=" flex justify-between mb-10 max-w-2xl mx-auto">
          <h1 class=" text-3xl  text-gray-500 font-bold ">Company Name</h1>

          <Link to="/company">
            <CiBoxList size={30} />
          </Link>
        </div>
        <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label
              class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
              for="inline-full-name"
            >
              Company Name
            </label>
          </div>
          <div class="md:w-1/2">
            <input
              name="name"
              defaultValue={formData?.name}
              onChange={handleChange}
              class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-green-500"
              id="inline-full-name"
              type="text"
              placeholder=" Enter Company Name"
            />
          </div>
        </div>
        <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label
              class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
              for="inline-password"
            >
              Contact Person Name
            </label>
          </div>
          <div class=" md:w-1/2 ">
            <input
              name="contact_person"
              defaultValue={formData?.contact_person}
              onChange={handleChange}
              class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="inline-password"
              type="text"
              placeholder="Enter Owner Name"
            />
          </div>
        </div>
        <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label
              class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
              for="phone"
            >
              Phone
            </label>
          </div>
          <div class=" md:w-1/2 ">
            <input
              name="phone"
              defaultValue={formData?.phone}
              onChange={handleChange}
              class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="phone"
              type="text"
              placeholder="EnterYour Phone Number"
            />
          </div>
        </div>
        <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label
              class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
              for="Email"
            >
              Email
            </label>
          </div>
          <div class=" md:w-1/2 ">
            <input
              defaultValue={formData?.email}
              name="email"
              onChange={handleChange}
              class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="Email"
              type="text"
              placeholder="Enter Your Email"
            />
          </div>
        </div>
        <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label
              class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
              for="Address"
            >
              Address
            </label>
          </div>
          <div class=" md:w-1/2 ">
            {/* <input
              defaultValue={formData?.address}
              name="address"
              onChange={handleChange}
              class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="Address"
              type="text"
              placeholder="Enter Your Address"
            /> */}
            <textarea
              defaultValue={formData?.address}
              onChange={handleChange}
              name="address"
              id="Address"
              rows="8"
              class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              placeholder="Write your address here..."
            ></textarea>
          </div>
        </div>
        <div class="md:flex md:items-center">
          <div class="md:w-1/3"></div>
          <div class="md:w-2/3">
            <button
              onClick={() => navigate(-1)}
              class=" w-24 bg-red-100 text-red-600 shadowfocus:shadow-outline focus:outline-none  font-bold py-2 px-4 rounded"
              type="button"
            >
              Back
            </button>
            <button
              class="mx-4 w-24 bg-gray-950 text-white shadowfocus:shadow-outline focus:outline-none  font-bold py-2 px-4 rounded"
              type="submit"
            >
              Update
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditCompany;
