import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { BsPlus } from "react-icons/bs";
import { BiMinus } from "react-icons/bi";
import { AiOutlineEye } from "react-icons/ai";
import DeleteAlert from "../Shared/DeleteAlert";

import axios from "axios";
import { useHeader } from "../../lib/headers";
import Swal from "sweetalert2";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
const Products = () => {
  const [products, setProducts] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [permission, setPermission] = useState("");
  const [allProducts, setAllProducts] = useState(null);
  const headers = useHeader();
  const [current, setCurrent] = useState(1);
  const itemsPerPage = 25;
  const fetchData = () => {
    axios
      .get(
        `${process.env.REACT_APP_ROOT_URL}/api/product/?page=${current}&search=${searchText}`,
        headers
      )
      .then((response) => {
        setAllProducts(response?.data);
        // Handle the response data
        const data = response.data?.results;
        setProducts(data);
      })
      .catch((error) => {
        // Handle any errors
        console.error(error);
      });
  };
  const fetchProfile = () => {
    axios
      .get(
        `${process.env.REACT_APP_ROOT_URL}/api/profile/
`,
        headers
      )
      .then((response) => {
        setPermission(response?.data);
        const data = response.data?.results;
      })
      .catch((error) => {
        // Handle any errors
        console.error(error);
      });
  };
  useEffect(() => {
    fetchProfile();
  }, []);
  useEffect(() => {
    fetchData();
  }, [current, searchText]);
  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(
            `${process.env.REACT_APP_ROOT_URL}/api/product/${id}`,
            headers
          )
          .then((response) => {
            // Handle the response data
            const newData = products.filter((item, i) => item?.id !== id);
            // const data = response.data?.results;
            setProducts(newData);
            // Swal.fire("Deleted!", "Your file has been deleted.", "success");
          })
          .catch((error) => {
            // Handle any errors
            console.error(error);
          });
      }
    });
  };

  const onChange = (page) => {
    // Calculate the new data range based on the page
    // const startIndex = (page - 1) * itemsPerPage;
    // const endIndex = startIndex + itemsPerPage;
    // Update the data to be displayed on the current page
    // setAllProducts(allProducts.results.slice(startIndex, endIndex));
    setCurrent(page);
  };
  console.log(" products: ", products)
  return (
    <div className=" m-5 mb-10 ">
      <div className=" flex justify-end">
        <Link
          to="/addproduct"
          className=" bg-gray-900 text-white px-4 py-2 rounded-lg my-2"
        >
          Add Product
        </Link>
      </div>
      <div class="mb-3">
        <input
          onChange={(e) => setSearchText(e?.target?.value)}
          type="search"
          class="relative m-0 h-10 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-primary focus:text-neutral-700 focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none    "
          id="exampleSearch"
          placeholder="Type query"
        />
      </div>
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg ">
        <table class="w-full text-sm text-left text-gray-500 ">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50 ">
            <tr>
              <th scope="col" class="px-6 py-3">
                Product name
              </th>
              {/* <th scope="col" class="px-6 py-3">
                Status
              </th> */}
              <th scope="col" class="px-6 py-3">
                Size
              </th>
              <th scope="col" class="px-6 py-3">
                category
              </th>
              <th scope="col" class="px-6 py-3">
                Brand
              </th>
              <th scope="col" class="px-6 py-3">
                Quantity
              </th>
              <th scope="col" class="px-6 py-3">
                Price
              </th>
              <th scope="col" class="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {products?.map((item) => (
              <tr className="bg-white border-b  " key={item.id}>
                <th
                  scope="row"
                  className="px-6 py-1 font-medium text-gray-900 whitespace-nowrap "
                >
                  <p>{item?.name}</p>
                  <div>
                    {item?.attributes?.length > 0 &&
                      item.attributes.map((i) => (
                        <div
                          className="text-gray-300 text-xs flex"
                          key={i.attribute}
                        >
                          <span>{i.attribute}</span>{" "}
                          {i.value && <span>:{i.value}</span>}
                        </div>
                      ))}
                  </div>
                </th>
                {/* <td className="px-6 py-4">{item?.status_display}</td> */}
                <td className="px-6 py-4">{item?.size}</td>
                <td className="px-6 py-4">{item?.category_name}</td>
                <td className="px-6 py-4">{item?.brand_name}</td>
                <td className="px-6 py-4">{item?.quantity}</td>
                <td className="px-6 py-4">{item?.sell_price}</td>
                <td className="px-6 py-4 w-20">
                  <div className="flex">
                    <Link
                      to={`/product/${item?.id}`}
                      className="mx-1 py-2 bg-sky-700 text-white px-2 rounded-full"
                    >
                      <AiOutlineEye size={20} />
                    </Link>
                    {permission?.is_superuser && (
                      <button
                        onClick={() => handleDelete(item?.id)}
                        className="mx-1 py-2 bg-red-600 text-white px-2 rounded-full"
                      >
                        <BiMinus size={20} />
                      </button>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="sticky bottom-0  flex justify-center items-center my-5 bg-white py-4 shadow ">
        <Pagination
          onChange={onChange}
          current={current}
          pageSize={itemsPerPage}
          total={allProducts?.count}
        />
      </div>
    </div>
  );
};

export default Products;
