import React, { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { RiLogoutCircleRLine } from "react-icons/ri";
import { useSelector } from "react-redux";

const Sidebar = () => {
  const navigate = useNavigate();
  const userProfile = useSelector((state) => state.userSlice.userProfile);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleSelectItem = (item) => {
    setSelectedItem(item);
  };

  const handleLogout = () => {
    localStorage.removeItem("refresh");
    localStorage.removeItem("token");
    navigate("/login");
  };

  return (
    <div className="sidebar">
      <aside
        id="logo-sidebar"
        className="fixed top-0 left-0 z-40 w-56 h-screen transition-transform -translate-x-full sm:translate-x-0"
        aria-label="Sidebar"
      >
        <div className="h-full px-3 py-4 overflow-y-auto bg-gray-50 ">
          <a
            href="https://flowbite.com/"
            className="flex items-center pl-2.5 mb-5"
          >
            <img
              src="https://static.vecteezy.com/system/resources/previews/010/178/938/non_2x/wms-letter-technology-logo-design-on-white-background-wms-creative-initials-letter-it-logo-concept-wms-letter-design-vector.jpg"
              className="h-6 mr-3 sm:h-7"
              alt="Flowbite Logo"
            />
            <span className="self-center text-xl font-semibold whitespace-nowrap ">
              WMS
            </span>
          </a>
          <ul className="space-y-2 font-medium">
            <li>
              <Link
                to="/"
                className={`flex items-center p-2 rounded-lg ${
                  selectedItem === null
                    ? "bg-gray-200  text-gray-900 "
                    : "text-gray-900 "
                } hover:bg-gray-100 `}
                onClick={() => handleSelectItem(null)}
              >
                <svg
                  aria-hidden="true"
                  className="w-6 h-6 text-gray-500 transition duration-75  group-hover:text-gray-900 "
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
                  <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>
                </svg>
                <span className="ml-3">Dashboard</span>
              </Link>
            </li>
            <li key={5455}>
              <Link
                to={`/soagenerator`}
                className={`flex items-center p-2 rounded-lg ${
                  selectedItem === "soagenerator"
                    ? "bg-gray-200  text-gray-900 "
                    : "text-gray-900 "
                } hover:bg-gray-100 `}
                onClick={() => handleSelectItem("soagenerator")}
              >
                <svg
                  aria-hidden="true"
                  className="w-6 h-6 text-gray-500 transition duration-75  group-hover:text-gray-900 "
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
                  <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>
                </svg>
                <span className="ml-3">SOA</span>
              </Link>
            </li>
            {userProfile?.permissions
              ?.filter((item) => item.is_active)
              ?.map((item) => (
                <li key={item.name}>
                  <Link
                    to={`/${item.name}`}
                    className={`flex items-center p-2 rounded-lg ${
                      selectedItem === item.name
                        ? "bg-gray-200  text-gray-900 "
                        : "text-gray-900 "
                    } hover:bg-gray-100 `}
                    onClick={() => handleSelectItem(item.name)}
                  >
                    <svg
                      aria-hidden="true"
                      className="w-6 h-6 text-gray-500 transition duration-75  group-hover:text-gray-900 "
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
                      <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>
                    </svg>
                    <span className="ml-3">{item.title}</span>
                  </Link>
                </li>
              ))}
          </ul>
          <button
            onClick={handleLogout}
            className="text-white bg-red-400 hover:bg-red-600 flex items-center p-2 rounded-lg w-full"
          >
            <RiLogoutCircleRLine size={30} />
            <span className="text-white flex-1 ml-3 whitespace-nowrap">
              Log out
            </span>
          </button>
        </div>
      </aside>
    </div>
  );
};

export default Sidebar;
