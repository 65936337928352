const defaultData =
  "1. The above prices are in Singapore Dollars before the 9% GST.\n2. Validity of Quote: 2 (Two) Weeks from the quotation date";

const TermsAndCondition = ({ info }) => {
  return (
    <div>
      <p className="font-bold text-xs">
        <strong>Terms & Conditions:</strong>
      </p>
      <p
        className="ml-6 text-xs bg-white w-full h-full"
        style={{ whiteSpace: "pre-wrap" }}
      >
        {info?.terms_and_conditions || defaultData}
      </p>
    </div>
  );
};

export default TermsAndCondition;
