import capitalizeWordLetter from "../lib/capitalizeFirstLetter";
import dateFormat from "dateformat";

const CustomerInfo = ({ customerInfo, pageNumber, customerInfoFor }) => {
  const now = new Date(customerInfo?.date ? customerInfo?.date : null);
  const formattedDate = dateFormat(now, "dd-mm-yyyy");
  return (
    <div className="flex justify-between w-full">
      <div className="flex items-center">
        <div className="">
          <h2 id="summary-heading" className="text-xl text-gray-900">
            <div>
              <h1 className="bg-gray-200 min-w-[280px] text-black text-sm font-bold">
                Customer Address:
              </h1>
              <span className="text-base font-bold leading-snug">
                {customerInfo?.company_name}
              </span>
              <p className="my-0 text-sm font-medium text-black">
                {addressFormatIntoLines(customerInfo?.company_address)}
              </p>
            </div>
            <div className="flex items-center">
              <h1 className="text-sm font-bold">Attention:</h1>
              <div className="ml-2 text-black text-sm bg-white flex-grow">
                {customerInfo?.attention}
              </div>
            </div>
            <div className="flex items-center">
              <h1 className="text-sm font-bold">Project name & add:</h1>
              <div className="ml-2 text-black text-sm bg-white flex-grow">
                {customerInfo?.site_address?.address}
              </div>
            </div>
            {customerInfoFor == "do" && (
              <div className="flex items-center">
                <h1 className="text-sm font-bold">Delivery Address:</h1>
                <div className="ml-2 text-black text-sm bg-white flex-grow">
                  {customerInfo?.site_contact}
                </div>
              </div>
            )}
          </h2>
        </div>
      </div>
      <div className="flex justify-end min-w-32 float-right">
        <div className="px-2 space-y-0">
          <div className="flex items-center justify-right mt-2">
            <dt className="text-sm font-bold text-gray-800 text-right">NO:</dt>
            <dd className="ml-2 text-sm font-bold text-gray-800">
              {customerInfo?.ref}
            </dd>
          </div>
          <div className="flex items-center justify-right">
            <dt className="text-sm font-bold text-gray-800">Date:</dt>
            <dd className="ml-2 text-sm font-bold text-gray-800">
              {formattedDate}
            </dd>
          </div>

          {customerInfoFor !== "quotation" && (
            <div className="flex items-center justify-right mt-2">
              <dt className="text-sm font-bold text-gray-800 text-right">
                Order No:
              </dt>
              <dd className="ml-2 text-sm font-bold text-gray-800">
                {customerInfo?.order_number}
              </dd>
            </div>
          )}
          {customerInfoFor == "do" && (
            <div className="flex items-center justify-right mt-2">
              <dt className="text-sm font-bold text-gray-800 text-right">
                Order By:
              </dt>
              <dd className="ml-2 text-sm font-bold text-gray-800">
                {customerInfo?.order_by}
              </dd>
            </div>
          )}
          {customerInfoFor == "invoice" && (
            <div className="flex items-center justify-right">
              <dt className="text-sm font-bold text-gray-800">P.O/QT No:</dt>
              <dd className="ml-2 text-sm font-bold text-gray-800">
                {customerInfo?.qt_number}
              </dd>
            </div>
          )}
          {customerInfoFor == "invoice" && (
            <div className="flex items-center justify-right">
              <dt className="text-sm font-bold text-gray-800">DO No:</dt>
              <dd className="ml-2 text-sm font-bold text-gray-800">
                {customerInfo?.do_no}
              </dd>
            </div>
          )}

          <div className="flex items-center justify-right">
            <dt className="text-sm font-bold text-gray-800">Terms:</dt>
            <dd className="ml-2 text-sm font-bold text-gray-800">
              {customerInfo?.terms}
            </dd>
          </div>
          <div className="flex items-center justify-right mt-2">
            <dt className="text-sm font-bold text-gray-800">Sales Person:</dt>
            <dd className="ml-2 text-sm font-bold text-gray-800 capitalize">
              {customerInfo?.sales_person
                ? capitalizeWordLetter(customerInfo?.sales_person)
                : ""}
            </dd>
          </div>
          <div className="flex items-center justify-right mt-2">
            <dt className="text-sm font-bold text-gray-800">Page:</dt>
            <dd className="ml-2 text-sm font-bold text-gray-800">
              {pageNumber ? pageNumber : null}
            </dd>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerInfo;

//   company address two lining
export function addressFormatIntoLines(add) {
  let splited = add?.split(",");
  const firstPart = splited
    ?.slice(0, splited.length - 2)
    ?.join(",")
    ?.trim();
  const secondPart = splited
    ?.slice(splited.length - 2)
    ?.join(",")
    ?.trim();
  var addValue = (
    <>
      <p>{firstPart}</p>
      <p>{secondPart}</p>
    </>
  );
  return addValue;
}
