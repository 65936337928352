import { useEffect, useRef, useState } from "react";
import CreatableSelect from "react-select/creatable";
import axios from "axios";
import Alert from "../Shared/Alert";
import PrintSOA from "./PrintSOA";
import ReactToPrint from "react-to-print";
import ParentPdf from "../PdfComp/ParentPdf";
const today = new Date().toISOString().split("T")[0];
const initialState = {
  company: "",
  from: today,
  to: today,
};
const SOAGenerator = () => {
  const [soaState, setSoaState] = useState(initialState);
  const [company, setcompany] = useState(null);
  const [companyData, setcompanyData] = useState([]);
  const [selectedCompanyData, setSelectedCompanyData] = useState([]);
  const [invoiceList, setInvoiceList] = useState([]);
  const [soaDetails, setSoaDetails] = useState({});

  const componentRef = useRef();
  const testCompRef = useRef();

  //setting token
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const handleChangeCompany = (newValue) => {
    setcompany(newValue);
  };

  const handleChange = (e) => {
    setSoaState((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  // Function to fetch data from the API
  const getcompanyList = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ROOT_URL}/api/company/`,
        { headers }
      );

      const conpanyData = response?.data?.results;

      const rest = conpanyData.map((item) => {
        return {
          label: item.name,
          value: item.id,
          sites: item.sites,
          id: item?.id,
          contact_person: item.contact_person,
          address: item.address,
          email: item.email,
          phone: item.phone,
          name: item.name,
        };
      });
      setcompanyData(rest);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async () => {
    if (!company) {
      return Alert("Please select company", "Error");
    }
    const pyload = {
      ...soaState,
      company: company.id,
    };
    const from = pyload?.from;
    const to = pyload?.to;
    const com = pyload?.company;

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ROOT_URL}/api/soa/?from_date=${from}&to_date=${to}&company=${com}`,
        { headers }
      );
      console.log("response of soa: ", response);
      setInvoiceList(response?.data?.invoice_list);
      setSoaDetails(response?.data?.details);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getcompanyList();
  }, []);
  return (
    <div>
      <div className="hidden">
        <ParentPdf componentRef={testCompRef} />
      </div>
      <div className="hidden">
        <PrintSOA
          details={soaDetails}
          componentRef={componentRef}
          invoiceList={invoiceList}
        />
      </div>
      <div className="mb-8">
        <h1 className=" text-3xl text-gray-950 font-bold text-center ">SOA</h1>
      </div>
      <div className="flex justify-center items-center">
        <CreatableSelect
          className="w-72"
          isClearable
          onChange={handleChangeCompany}
          options={companyData}
          value={company}
          placeholder="Select or Create Company"
        />
      </div>
      <div className="flex justify-center items-center gap-5 mt-3">
        <div className="w-full mb-3">
          <label class="block mb-2 text-sm font-medium text-gray-900 ">
            From
          </label>
          <input
            onChange={handleChange}
            value={soaState.from}
            name="from"
            type="date"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark::bg-gray-700 dark::border-gray-600 dark::placeholder-gray-400 dark::text-white dark::focus:ring-blue-500 dark::focus:border-blue-500"
            placeholder="Select date"
          />
        </div>
        <div className="w-full mb-3">
          <label class="block mb-2 text-sm font-medium text-gray-900 ">
            To
          </label>
          <input
            onChange={handleChange}
            value={soaState.to}
            name="to"
            type="date"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark::bg-gray-700 dark::border-gray-600 dark::placeholder-gray-400 dark::text-white dark::focus:ring-blue-500 dark::focus:border-blue-500"
            placeholder="Select date"
          />
        </div>
      </div>
      <div className="flex justify-center items-center mt-4">
        <button
          onClick={handleSubmit}
          className="  bg-gray-900 text-white px-4 py-2 rounded-lg mr-[2%] w-40 "
        >
          Submit
        </button>
      </div>
      <ReactToPrint
        trigger={() => (
          <button className=" my-2 bg-gray-900 text-white px-4 py-2 rounded-lg mr-[2%] ">
            Print
          </button>
        )}
        content={() => componentRef.current}
        documentTitle={"Test Title"}
      />
      <ReactToPrint
        trigger={() => (
          <button className=" my-2 bg-gray-900 text-white px-4 py-2 rounded-lg mr-[2%] ">
            Test Print
          </button>
        )}
        content={() => testCompRef.current}
        documentTitle={"Test Title"}
      />
    </div>
  );
};

export default SOAGenerator;
