import React, { useEffect, useState } from "react";
import Alert from "../Shared/Alert";
import SelectInput from "../Shared/SelectInput";
import axios from "axios";
import { useHeader } from "../../lib/headers";
import { useNavigate } from "react-router-dom";
import SelectInput2 from "../Shared/SelectedInput2";
import "react-quill/dist/quill.snow.css";
import CreatableSelect from "react-select/creatable";

const createOption = (label, id, category) => ({
  id,
  label,
  value: label,
  category: category,
  name: label,
  // value: label.toLowerCase().replace(/\W/g, ""),
});
const createOptionForCategory = (label, id) => ({
  id,
  label,
  value: id,
  name: label,
  // value: label.toLowerCase().replace(/\W/g, ""),
});
const AddVariant = () => {
  const [data, setData] = useState([
    {
      attribute: "",
      value: "",
      is_show: true,
    },
  ]);
  const [data2, setData2] = useState([
    {
      variant: "",
      value: [],
    },
  ]);

  const [attributes, setAttributes] = useState([]);
  const [variants, setVariants] = useState([]);
  const [brand, setBrand] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState();
  const [category, setCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [value, setValue] = useState();
  const [value2, setValue2] = useState();
  const [isReload, setIsReload] = useState(1);
  const [isReload2, setIsReload2] = useState(1);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [is_purchase, setIs_purchase] = useState(true);
  const [has_variant, setHas_variant] = useState(true);
  const [has_Attribute, setHas_Attribute] = useState(false);

  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const headers = useHeader();

  const fetchCategory = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_ROOT_URL}/api/category/`, headers)
        .then((response) => {
          const data = response.data?.results;
          setCategory([
            ...data.map((item) => createOptionForCategory(item.name, item?.id)),
          ]);
          // setCategory(response.data?.results);
        });
    } catch (error) {
      console.error(error);
    }
  };
  const fetchBrand = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_ROOT_URL}/api/brand/`, headers)
        .then((response) => {
          const data = response.data?.results;

          setOptions([
            ...data.map((item) =>
              createOption(item.name, item?.id, item?.category)
            ),
          ]);
          setBrand(response.data?.results);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const handleCreateCategory = async (inputValue, index) => {
    setIsLoading(true);

    const data = {
      name: inputValue,
    };

    try {
      let data2;
      await axios
        .post(`${process.env.REACT_APP_ROOT_URL}/api/category/`, data, headers)
        .then((res) => {
          data2 = res?.data;
          setCategory([createOptionForCategory(data2.name, data2?.id)]);
          setSelectedCategory({
            label: data2.name,
            id: data2?.id,
            value: data2?.id,
          });
          setIsLoading(false);
        });
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchCategory();
  }, []);
  useEffect(() => {
    // if (selectedCategory?.value) {
    //   fetchBrand();
    // }
    fetchBrand();
  }, []);

  // Fetch data from the API endpoint
  const fetchAttributes = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_ROOT_URL}/api/attribute/`, headers)
        .then((response) => {
          setAttributes(response.data?.results);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const fetchVariants = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_ROOT_URL}/api/variant/`, headers)
        .then((response) => {
          setVariants(response.data?.results);
        });
    } catch (error) {
      console.error(error);
    }
  };

  // Trigger the API call when the component mounts
  useEffect(() => {
    fetchAttributes();
  }, [isReload]);
  useEffect(() => {
    fetchVariants();
  }, [isReload2]);
  const handlePluse = () => {
    setData([
      ...data,
      {
        attribute: "",
        value: "",
        is_show: true,
      },
    ]);
  };
  const handlePluse2 = () => {
    setData2([
      ...data2,
      {
        variant: "",
        value: [],
      },
    ]);
  };
  const handleMinus = (index) => {
    const list = [...data];
    list.splice(index, 1);
    setData(list);
  };
  const handleMinus2 = (index) => {
    const list = [...data2];
    list.splice(index, 1);
    setData2(list);
  };

  const handleSubmitProduct = async (e) => {
    e.preventDefault();
    // const requestData = {
    //   name: name,
    //   // status: status,
    //   attributes: data,
    //   variants: has_variant ? data2 : [],
    //   category: selectedCategory?.value,
    //   brand: selectedBrand?.id,
    //   is_sell: is_sell,
    //   has_variant: has_variant,
    //   has_attribute: has_Attribute,
    //   is_purchase: is_purchase,
    //   color: color,
    // };

    console.log(data2);
  };

  return (
    <div>
      <form onSubmit={handleSubmitProduct}>
        <div class="grid gap-6 mb-6 md:grid-cols-2">
          <div>
            <label
              for="Category"
              class="block mb-2 text-sm font-medium text-gray-900 dark::text-white"
            >
              Category
            </label>
            <CreatableSelect
              // required={true}
              isClearable
              isDisabled={isLoading}
              isLoading={isLoading}
              onChange={(newValue) => setSelectedCategory(newValue)}
              onCreateOption={handleCreateCategory}
              options={category}
              value={
                selectedCategory && {
                  label: selectedCategory?.label,
                  value: selectedCategory?.value,
                }
              }
              name="category"
              placeholder="Select or create category..."
            />
          </div>
        </div>

        {/* <hr className=" my-3" /> */}
        {has_Attribute && (
          <div>
            <h1 className=" font-bold">Attribute Section</h1>
            <div
              className={`${
                data?.length > 0 && " rounded border border-gray-200 p-4 mb-5 "
              }`}
            >
              {data?.map((item, index) => {
                return (
                  <div key={index} className=" w-full">
                    <SelectInput
                      selectedCategory={selectedCategory}
                      isReload={isReload}
                      setIsReload={setIsReload}
                      attributes={attributes}
                      // handleChange={handleChange}
                      index={index}
                      // onChange={(e) => handleChange(e, index)}
                      item={item}
                      setValue={setValue}
                      setData={setData}
                      data={data}
                      handlePluse={handlePluse}
                      handleMinus={handleMinus}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {has_variant && (
          <div>
            <h1 className=" font-bold">Variant Section</h1>
            <div
              className={`${
                data2.length > 0 && " rounded border border-gray-200 p-4 mb-5 "
              }`}
            >
              {data2?.map((item, index) => {
                return (
                  <div key={index} className=" w-full">
                    <SelectInput2
                      selectedCategory={selectedCategory}
                      isReload2={isReload2}
                      setIsReload2={setIsReload2}
                      variants={variants}
                      // handleChange={handleChange}
                      index={index}
                      // onChange={(e) => handleChange(e, index)}
                      item={item}
                      setValue2={setValue2}
                      setData2={setData2}
                      data2={data2}
                      handlePluse2={handlePluse2}
                      handleMinus2={handleMinus2}
                      selectedOptions={selectedOptions}
                      setSelectedOptions={setSelectedOptions}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        )}

        <button
          type="submit"
          // onClick={handleSubmitProduct}
          className="mt-16 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default AddVariant;
