import React from "react";
import { Link } from "react-router-dom";
import { BiEdit } from "react-icons/bi";
import { AiOutlineEye } from "react-icons/ai";
import { AiFillDelete } from "react-icons/ai";
import DeleteAlert from "../Shared/DeleteAlert";
import { useSelector } from "react-redux";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";

const DOTable = ({
  handleDelete,
  deliveryOrder,
  handlePageChange,
  currentPage,
  itemsPerPage,
  totalItems,
  setSearchText,
}) => {
  console.log("delivery order: ", deliveryOrder);
  const { doEditId } = useSelector((state) => state.editState);
  return (
    <div className=" m-5  ">
      <div className=" flex justify-end">
        <Link
          to="/addDO"
          className=" bg-gray-900 text-white px-4 py-2 rounded-lg my-2"
        >
          Create Do
        </Link>
      </div>
      <div class="mb-3">
        <input
          onChange={(e) => setSearchText(e?.target?.value)}
          type="search"
          class="relative m-0 h-10 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-primary focus:text-neutral-700 focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none    "
          id="exampleSearch"
          placeholder="Type query"
        />
      </div>
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg ">
        <table class="w-full text-sm text-left text-gray-500 ">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50 ">
            <tr>
              <th scope="col" class="px-6 py-3">
                Code
              </th>
              <th scope="col" class="px-6 py-3">
                Date
              </th>
              <th scope="col" class="px-6 py-3">
                Company Name
              </th>
              <th scope="col" class="px-6 py-3">
                Source
              </th>
              <th scope="col" class="px-6 py-3">
                Grand Total
              </th>
              <th scope="col" class="px-6 py-3">
                Sub Total
              </th>
              <th scope="col" class="px-6 py-3">
                Status
              </th>
              <th scope="col" class="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {deliveryOrder?.map((item) => (
              <tr
                class={
                  item.id == doEditId
                    ? "bg-yellow-400 border-b"
                    : "bg-white border-b"
                }
              >
                <th class="px-6 py-4">
                  <p>{item?.ref}</p>
                </th>
                <td class="px-6 py-4">{item?.date}</td>
                <td class="px-6 py-4">{item?.company_name}</td>
                <td class="px-6 py-4">{item?.source}</td>
                <td class="px-6 py-4">{(item?.grand_total).toFixed(2)}</td>
                <td class="px-6 py-4">{(item?.sub_total).toFixed(2)}</td>
                <td class="px-6 py-4">{item?.status}</td>
                <td className="px-6 py-4 w-64  ">
                  <div className="  w-full">
                    {/* <button className=" bg-green-600 text-white px-2 py-1 rounded-full">
                    Add Project
                  </button> */}

                    <button className="mx-1 py-2 bg-yellow-600 text-white px-2  rounded-full">
                      <Link to={`/addDO/${item?.id}?ref=${item?.ref}`}>
                        <BiEdit size={16} />
                      </Link>
                    </button>
                    <button className="mx-1 py-2 bg-black text-white px-2  rounded-full">
                      <Link to={`/viewdo/${item?.id}`}>
                        <AiOutlineEye size={16} />
                      </Link>
                    </button>
                    <button
                      onClick={() => handleDelete(item?.id)}
                      className=" py-2 bg-red-600 text-white px-2  rounded-full"
                    >
                      <AiFillDelete size={16} />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="sticky bottom-0  flex justify-center items-center my-5 bg-white py-4 shadow ">
        <Pagination
          onChange={handlePageChange}
          current={currentPage}
          pageSize={itemsPerPage}
          total={totalItems}
        />
      </div>
    </div>
  );
};

export default DOTable;
