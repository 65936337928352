import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BiEdit } from "react-icons/bi";
import { AiOutlineEye } from "react-icons/ai";
import { AiFillDelete } from "react-icons/ai";
import axios from "axios";
import Swal from "sweetalert2";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";

const PurchaseTable = () => {
  const [quotaionData, setquotaionData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 25;
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  //setting token
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  // delete function
  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(
            `${process.env.REACT_APP_ROOT_URL}/api/purchase/${id}/`,
            { headers }
          );
          // After successful deletion, refresh the data
          fetchData();
          Swal.fire("Deleted!", "Your item has been deleted.", "success");
        } catch (error) {
          console.error(error);
          Swal.fire(
            "Error!",
            "An error occurred while deleting the item.",
            "error"
          );
        }
      }
    });
  };

  // Function to fetch data from the API
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ROOT_URL}/api/purchase/?page=${currentPage}`,
        { headers }
      );
      setTotalItems(response?.data?.count);
      setquotaionData(response?.data?.results);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage]);
  return (
    <div className=" m-5  ">
      <div className=" flex justify-end">
        <Link
          to="/add-purchase"
          className=" bg-gray-900 text-white px-4 py-2 rounded-lg my-2"
        >
          Add Purchase
        </Link>
      </div>
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg ">
        <table class="w-full text-sm text-left text-gray-500 dark::text-gray-400">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark::bg-gray-700 dark::text-gray-400">
            <tr>
              <th scope="col" class="px-6 py-3">
                Code
              </th>
              <th scope="col" class="px-6 py-3">
                Date
              </th>
              {/* <th scope="col" class="px-6 py-3">
                Project Name
              </th> */}
              <th scope="col" class="px-6 py-3">
                Company Name
              </th>
              {/* <th scope="col" class="px-6 py-3">
                Contact Person
              </th> */}
              <th scope="col" class="px-6 py-3">
                Grand Total
              </th>
              <th scope="col" class="px-6 py-3">
                Status
              </th>
              <th scope="col" class="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {quotaionData?.map((item) => (
              <tr
                key={item?.id}
                class="bg-white border-b dark::bg-gray-800 dark::border-gray-700"
              >
                <th class="px-6 py-4">
                  <p>{item?.ref}</p>
                </th>
                <td class="px-6 py-4">{item?.date}</td>
                {/* <td class="px-6 py-4">{item?.project_name}</td> */}
                <td class="px-6 py-4">{item?.company_name}</td>
                {/* <td class="px-6 py-4">{item?.contact_person}</td> */}
                <td class="px-6 py-4">{item?.grand_total}</td>
                <td class="px-6 py-4">{item?.status}</td>
                <td className="px-6 py-4  ">
                  <div className=" flex items-center  w-full">
                    {/* <button className=" bg-green-600 text-white px-2 py-1 rounded-full">
                    Add Project
                  </button> */}

                    <button className="mx-1 py-2 bg-yellow-600 text-white px-2  rounded-full">
                      <Link to={`/edit-purchase/${item?.id}`}>
                        <BiEdit size={16} />
                      </Link>
                    </button>
                    <button className="mx-1 py-2 bg-black text-white px-2  rounded-full">
                      <Link to={`/view-purchase/${item?.id}`}>
                        <AiOutlineEye size={16} />
                      </Link>
                    </button>
                    <button
                      className=" py-2 bg-red-600 text-white px-2  rounded-full"
                      onClick={() => handleDelete(`${item?.id}`)}
                    >
                      <AiFillDelete size={16} />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="sticky bottom-0  flex justify-center items-center my-5 bg-white py-4 shadow ">
        <Pagination
          onChange={handlePageChange}
          current={currentPage}
          pageSize={itemsPerPage}
          total={totalItems}
        />
      </div>
    </div>
  );
};

export default PurchaseTable;
