import TermsAndCondition from "./TermsAndCondition";

const defaultBankInfo =
  "OCBC BANK, North Branch\nBank/Branch Code: 7339/712\nBank Account Number: 712-035435-001\nSwift Code: OCBCSGSG\nBy PayNow to AMA UEN: 201700945E";

const FooterInfo = ({
  acceptedBy = true,
  bankInfo = defaultBankInfo,
  showBankInfo = false,
  showTermsCondition = true,
}) => {
  return (
    <section className="repeating-section">
      {showTermsCondition && (
        <div className="mb-5">
          <TermsAndCondition />
        </div>
      )}
      <div className="flex justify-between items-center px-4 mt-[-30px]">
        <div className="flex flex-col">
          {showBankInfo && (
            <div className="flex items-center gap-3 mb-2">
              <div>
                <p className="font-bold text-xs">
                  Bank Information for Payment Transfer:
                </p>
                <p
                  className="text-xs mt-1 bg-white w-full h-full"
                  style={{ whiteSpace: "pre-wrap" }}
                >
                  {bankInfo}
                </p>
              </div>
              <div>OR</div>
              <div>
                <img className="h-[95px]" src="/Payment_QR_Code.png" />
              </div>
            </div>
          )}
          <img className="w-24 mb-1" src="/signature.png" alt="" />
          <h1 className="text-xs font-bold border-t-2 border-gray-950">
            Authorised Signature & Company Stamp
          </h1>
        </div>
        {acceptedBy && (
          <div className="flex flex-col">
            <h1 className="w-60 font-bold mb-5">Accepted by:</h1>
            <h1 className="text-xs font-bold border-t-2 border-gray-950">
              Authorised Signature & Company Stamp
            </h1>
          </div>
        )}
        <div>
          <img className="w-24 mb-1" src="/uaf.jpg" alt="" />
          <img className="w-20" src="/biz.jpg" alt="" />
        </div>
      </div>
    </section>
  );
};

export default FooterInfo;
