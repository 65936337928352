import { saveAs } from "file-saver";
import Papa from "papaparse";

export const exportToCSV = (data, columns, fileName) => {
  // Filter data to include only specified columns
  const filteredData = data.map((item) => {
    const filteredItem = {};
    columns.forEach((column) => {
      if (column === "date") {
        filteredItem["Invoice Date"] = item[column];
      } else if (column === "ref") {
        filteredItem["Invoice Number"] = item[column];
      } else if (column === "paid_amount") {
        filteredItem["Paid Amount"] = item[column];
      } else if (column === "source") {
        filteredItem["Source"] = item[column];
      } else if (column === "status") {
        filteredItem["Status"] = item[column];
      } else if (column === "grand_total") {
        filteredItem["Grand Total"] = item[column];
      } else if (column === "compnay_name") {
        filteredItem["Company Name"] = item[column];
      } else if (column === "cancel_reason") {
        filteredItem["Reason of cancel"] = item[column];
      } else {
        filteredItem[column] = item[column];
      }
    });
    return filteredItem;
  });

  // Convert filtered data to CSV
  const csv = Papa.unparse(filteredData);
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  saveAs(blob, `${fileName}.csv`);
};
