import { BrowserRouter, Routes, Route, useParams } from "react-router-dom";
import Home from "./Components/Home/Home";
import PrivateRoute from "./Components/Shared/PrivateRoute";
import SignIn from "./Components/Auth/SignIn/SignIn";
import Layout from "./Components/Layout/Layout";
import Products from "./Components/Products/Products";
import AddProduct from "./Components/Products/AddProduct";
import Test from "./Components/Test/Test";
import Company from "./Components/Company/Company";
import AddCompany from "./Components/Company/AddCompany";
import Projects from "./Components/Projects/Projects";
import AddProjects from "./Components/Projects/AddProjects";
import Quotition from "./Components/Quotition/Quotition";
import QuotitionTable from "./Components/Quotition/QuotitionTable";
import AddQuotition from "./Components/Quotition/AddQuotition";
import DO from "./Components/DO/DO";
import AddDO from "./Components/DO/AddDO";
import Invoice from "./Components/Invoice/Invoice";
import AddInvoice from "./Components/Invoice/AddInvoice";
import Navbar from "./Components/Shared/Navbar";
import EditCompany from "./Components/Company/EditCompany";
import EditProject from "./Components/Projects/EditProject";
import ViewQuotation from "./Components/Quotition/ViewQuotation";
import ViewDO from "./Components/DO/ViewDO";
import Category from "./Components/Category/Category";
import AddCategory from "./Components/Category/AddCategory";
import EditCategory from "./Components/Category/EditCategory";
import Brand from "./Components/Brand/Brand";
import AddBrand from "./Components/Brand/AddBrand";
import EditBrand from "./Components/Brand/EditBrand";
import Employee from "./Components/Employee/Employee";
import AddUser from "./Components/Employee/AddEmployee";
import EditEmployee from "./Components/Employee/EditEmployee";
import ViewInvoice from "./Components/Invoice/ViewInvoice";
import ViewProduct from "./Components/Products/ViewProduct";
import Purchase from "./Components/Purchase/Purchase";
import AddPurchase from "./Components/Purchase/AddPurchase";
import ViewPurchase from "./Components/Purchase/ViewPurchase";
import LayoutWithoutSidebar from "./Components/Layout/LayoutWithoutSidebar";
import POS from "./Components/POS/POS";
import POS2 from "./Components/POS/POS2";
import Order from "./Components/POS/Order";
import OrderList from "./Components/Order/OrderList";
import OrderDetail from "./Components/Order/OrderDetail";
import Inventory from "./Components/Invantory/Inventory";
import InventoryDetails from "./Components/Invantory/InventoryDetails";
import POSInvoice from "./Components/POS/POSInvoice";
import MultiInvantory from "./Components/Invantory/MultiInvantory";
import MultiInventoryDetails from "./Components/Invantory/MultiInventoryDetails";
import SubProducts from "./Components/Products/SubProducts";
import AddVariant from "./Components/Products/AddVariant";
import SOAGenerator from "./Components/SOA/SOAGenerator";
function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/login" element={<SignIn />} />

        <Route path="/pos">
          <Route path="/pos" element={<POS />} />
          <Route path="/pos/order" element={<Order />} />
          <Route path="/pos/order/:id" element={<POSInvoice />} />
          <Route path="/pos/niz" element={<POS2 />} />
        </Route>

        <Route
          path="/"
          element={
            <PrivateRoute>
              <Layout />
            </PrivateRoute>
          }
        >
          <Route path="/" element={<PrivateRoute>{<Home />}</PrivateRoute>} />
          <Route
            path="/soagenerator"
            element={
              <PrivateRoute>
                <SOAGenerator />
              </PrivateRoute>
            }
          />
          {/* product route  */}
          <Route
            path="/product"
            element={
              <PrivateRoute>
                <Products />
              </PrivateRoute>
            }
          />
          <Route
            path="/sub-product"
            element={
              <PrivateRoute>
                <SubProducts />
              </PrivateRoute>
            }
          />
          <Route
            path="/product/:id"
            element={
              <PrivateRoute>
                <ViewProduct />
              </PrivateRoute>
            }
          />

          <Route
            path="/addproduct"
            element={
              <PrivateRoute>
                <AddProduct />
              </PrivateRoute>
            }
          />
          <Route
            path="/addvariant"
            element={
              <PrivateRoute>
                <AddVariant />
              </PrivateRoute>
            }
          />

          {/* category route  */}
          <Route
            path="/category"
            element={
              <PrivateRoute>
                <Category />
              </PrivateRoute>
            }
          />
          <Route
            path="/add-category"
            element={
              <PrivateRoute>
                <AddCategory />
              </PrivateRoute>
            }
          />
          <Route
            path="/edit-category/:id"
            element={
              <PrivateRoute>
                <EditCategory />
              </PrivateRoute>
            }
          />

          {/* brand route  */}
          <Route
            path="/brands"
            element={
              <PrivateRoute>
                <Brand />
              </PrivateRoute>
            }
          />

          <Route
            path="/add-brand"
            element={
              <PrivateRoute>
                <AddBrand />
              </PrivateRoute>
            }
          />
          <Route
            path="/edit-brand/:id"
            element={
              <PrivateRoute>
                <EditBrand />
              </PrivateRoute>
            }
          />

          {/* company route  */}
          <Route
            path="/company"
            element={
              <PrivateRoute>
                <Company />
              </PrivateRoute>
            }
          />
          <Route
            path="/addcompany"
            element={
              <PrivateRoute>
                <AddCompany />
              </PrivateRoute>
            }
          />
          <Route
            path="/editcompany/:id"
            element={
              <PrivateRoute>
                <EditCompany />
              </PrivateRoute>
            }
          />
          {/* User route  */}
          <Route
            path="/user"
            element={
              <PrivateRoute>
                <Employee />
              </PrivateRoute>
            }
          />
          <Route
            path="/add-user"
            element={
              <PrivateRoute>
                <AddUser />
              </PrivateRoute>
            }
          />
          <Route
            path="/user/:id"
            element={
              <PrivateRoute>
                <AddUser />
                {/* <EditEmployee /> */}
              </PrivateRoute>
            }
          />
          {/* project route  */}
          <Route
            path="/projects"
            element={
              <PrivateRoute>
                <Projects />
              </PrivateRoute>
            }
          />
          <Route
            path="/addproject"
            element={
              <PrivateRoute>
                <AddProjects />
              </PrivateRoute>
            }
          />
          <Route
            path="/editproject/:id"
            element={
              <PrivateRoute>
                <EditProject />
              </PrivateRoute>
            }
          />

          {/* QUOTAtion route  */}
          <Route
            path="/quatation"
            element={
              <PrivateRoute>
                <Quotition />
              </PrivateRoute>
            }
          />
          <Route
            path="/viewquotation/:id"
            element={
              <PrivateRoute>
                <ViewQuotation />
              </PrivateRoute>
            }
          />
          <Route
            path="/addquotation"
            element={
              <PrivateRoute>
                <AddQuotition />
              </PrivateRoute>
            }
          />
          <Route
            path="/addquotition/:id"
            element={
              <PrivateRoute>
                <AddQuotition />
              </PrivateRoute>
            }
          />

          {/* do route  */}
          <Route
            path="/deliver_order"
            element={
              <PrivateRoute>
                <DO />
              </PrivateRoute>
            }
          />
          <Route
            path="/viewdo/:id"
            element={
              <PrivateRoute>
                <ViewDO />
              </PrivateRoute>
            }
          />
          <Route
            path="/addDO"
            element={
              <PrivateRoute>
                <AddDO />
              </PrivateRoute>
            }
          />
          <Route
            path="/addDO/:id"
            element={
              <PrivateRoute>
                <AddDO />
              </PrivateRoute>
            }
          />
          <Route
            path="/invoice"
            element={
              <PrivateRoute>
                <Invoice />
              </PrivateRoute>
            }
          />
          <Route
            path="/addinvoice"
            element={
              <PrivateRoute>
                <AddInvoice />
              </PrivateRoute>
            }
          />
          <Route
            path="/edit-invoice/:id"
            element={
              <PrivateRoute>
                <AddInvoice />
              </PrivateRoute>
            }
          />
          <Route
            path="/view-invoice/:id"
            element={
              <PrivateRoute>
                <ViewInvoice />
              </PrivateRoute>
            }
          />

          {/* purchase route  */}
          <Route
            path="/purchase"
            element={
              <PrivateRoute>
                <Purchase />
              </PrivateRoute>
            }
          />
          <Route
            path="/add-purchase"
            element={
              <PrivateRoute>
                <AddPurchase />
              </PrivateRoute>
            }
          />
          <Route
            path="/edit-purchase/:id"
            element={
              <PrivateRoute>
                <AddPurchase />
              </PrivateRoute>
            }
          />
          <Route
            path="/view-purchase/:id"
            element={
              <PrivateRoute>
                <ViewPurchase />
              </PrivateRoute>
            }
          />
          <Route
            path="/order"
            element={
              <PrivateRoute>
                <OrderList />
              </PrivateRoute>
            }
          />
          <Route
            path="/order/:id"
            element={
              <PrivateRoute>
                <OrderDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/inventory"
            element={
              <PrivateRoute>
                <Inventory />
              </PrivateRoute>
            }
          />
          <Route
            path="/inventory/:id"
            element={
              <PrivateRoute>
                <InventoryDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/muti-inventory-details/:id"
            element={
              <PrivateRoute>
                <MultiInventoryDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/multi-inventory/:id"
            element={
              <PrivateRoute>
                <MultiInvantory />
              </PrivateRoute>
            }
          />
          {/* test route  */}
          <Route
            path="/test"
            element={
              <PrivateRoute>
                <Test />
              </PrivateRoute>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
