import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import Alert from "../Shared/Alert";
import ReactToPrint from "react-to-print";
import PrintPurchase from "./PrintPurchase";
const ViewPurchase = () => {
  const [quotaionData, setquotaionData] = useState([]);
  const [items, setItems] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();
  //setting token
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const componentRef = useRef();
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ROOT_URL}/api/purchase/${id}`,
        { headers }
      );
      setquotaionData(response?.data);
      setItems(response?.data?.product);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const calculateTotalPrice = (item) => {
    const totalPrice = item.unit_price * item.quantity;
    return totalPrice;
  };
  const subtotal = quotaionData?.product?.reduce(
    (sum, item) => sum + calculateTotalPrice(item),
    0
  );
  const handleStatus = async (status) => {
    const data = {
      status: status,
    };
    try {
      await axios
        .patch(`${process.env.REACT_APP_ROOT_URL}/api/purchase/${id}/`, data, {
          headers,
        })
        .then((res) => {
          setquotaionData(res?.data);
          Alert("Status Updated", "success");
        });
    } catch (error) {
      console.error(error);
    }
  };
  function calculateTotalPriceAfterDiscount(isItemDiscount, item) {
    const initialTotal =
      Number(
        item?.category_name === "Ducting" ? item?.unit_price : item?.sell_price
      ) * Number(item?.new_quantity);
    console.log("initialTotal", initialTotal);
    if (isItemDiscount && item?.item_discount) {
      const discountPercentage = Number(item.item_discount);

      const discountAmount = (initialTotal * discountPercentage) / 100;
      console.log("discountAmount", discountAmount);
      const totalAfterPercentageDiscount = initialTotal - discountAmount;
      return Math.round(discountAmount);
      // if (isFlatDiscount) {
      //   const discountAmount = Number(item.item_discount);
      //   const totalAfterFlatDiscount = initialTotal - discountAmount;
      //   return Math.round(totalAfterFlatDiscount);
      // } else {

      //   const discountPercentage = Number(item.item_discount);
      //   const discountAmount = (initialTotal * discountPercentage) / 100;
      //   const totalAfterPercentageDiscount = initialTotal - discountAmount;
      //   return Math.round(totalAfterPercentageDiscount);
      // }
    } else {
      return initialTotal.toFixed(2);
    }
  }
  return (
    <div>
      <div className=" hidden">
        <PrintPurchase
          componentRef={componentRef}
          quotaionData={quotaionData}
          items={items}
        />
      </div>
      <div className=" flex items-center justify-between">
        <button
          onClick={() => navigate(-1)}
          className=" border rounded p-2  flex  items-center hover:bg-black hover:text-white "
        >
          <IoIosArrowBack />
          <span className=" ml-2"> Back</span>
        </button>
        <ReactToPrint
          trigger={() => (
            <button className=" px-10 text-center  border rounded p-2  flex  items-center hover:bg-black hover:text-white ">
              Print
            </button>
          )}
          content={() => componentRef.current}
        />
      </div>
      <div class=" mt-10  w-full">
        <div className=" flex justify-between items-center">
          <div>
            <h2 id="summary-heading" class="pb-0 text-xl  text-gray-900">
              <>
                <span className=" font-medium"> Company:</span>{" "}
                <span>{quotaionData?.company_name} </span>
              </>
            </h2>
            <div class="flex items-center">
              <dt class="text-sm font-medium text-gray-800">
                Company Address:
              </dt>
              <dd class=" ml-2 text-sm ">{quotaionData?.company_address}</dd>
            </div>

            <div class="flex items-center">
              <dt class="text-sm font-medium text-gray-800">Attention:</dt>
              <dd class=" ml-2 text-sm ">{quotaionData?.attention}</dd>
            </div>

            {/* <div class="flex items-center">
              <dt class="text-sm font-medium text-gray-800">
                Project name & Address:
              </dt>
              <dd class=" ml-2 text-sm ">
                {quotaionData?.site_address?.address}
              </dd>
            </div> */}
          </div>

          <div className=" ">
            <div class="flex items-center">
              <dt class="text-sm font-medium text-gray-800">NO:</dt>
              <dd class=" ml-2 text-sm ">{quotaionData?.ref}</dd>
            </div>

            <div class="flex items-center">
              <dt class="text-sm font-medium text-gray-800">Date:</dt>
              <dd class=" ml-2 text-sm ">{quotaionData?.date}</dd>
            </div>

            <div class="flex items-center">
              <dt class="text-sm font-medium text-gray-800">Status:</dt>
              <dd class=" ml-2 text-sm ">{quotaionData?.status}</dd>
            </div>

            <div class="flex items-center">
              <dt class="text-sm font-medium text-gray-800">Terms:</dt>
              <dd class=" ml-2 text-sm ">{quotaionData?.terms}</dd>
            </div>

            <div class="flex items-center">
              <dt class="text-sm font-medium text-gray-800">Sales Person:</dt>
              <dd class=" ml-2 text-sm ">{quotaionData?.sales_person}</dd>
            </div>

            {/* <div class=" space-y-1 px-2 py-4">
                <div class="flex items-center justify-between">
                  <dt class="text-sm font-medium text-gray-800">Date:</dt>
                  <dd class=" ml-2 text-sm ">{quotaionData?.date}</dd>
                </div>
                <div class="flex items-center justify-between mt-2">
                  <dt class="text-sm font-medium text-gray-800">Status: </dt>
                  <dd class=" ml-2 text-sm  ">{quotaionData?.status}</dd>
                </div>
              </div> */}
          </div>
        </div>
        <section class="rounded-lg col-span-12 bg-white lg:col-span-8 w-full">
          <table class="w-full text-sm text-left text-gray-500 dark::text-gray-400">
            <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark::bg-gray-700 dark::text-gray-400">
              <tr>
                <th>S/N</th>
                <th scope="col" class="px-6 py-3">
                  Product Description
                </th>
                <th scope="col" class="px-6 py-3  ">
                  Size
                </th>
                <th scope="col" class="px-6 py-3">
                  Unit Price
                </th>
                <th scope="col" class="px-6 py-3">
                  Quantity
                </th>
                {quotaionData?.is_item_discount && (
                  <th scope="col" class="px-6 py-3">
                    Discount
                  </th>
                )}
                <th scope="col" class="px-6 py-3">
                  Total
                </th>
              </tr>
            </thead>
            <tbody>
              {items?.map((item, index) => (
                <tr
                  className={` bg-white border-b dark::bg-gray-800 dark::border-gray-700`}
                  key={item.id}
                >
                  <th className=" text-center">{index + 1}</th>
                  <th
                    scope="row"
                    className="px-6 py-1 font-medium text-gray-900 whitespace-nowrap dark::text-white"
                  >
                    <div className="text-dark text-xs flex">
                      {item?.category_name === "Ducting" && (
                        <span className=" space-x-1 uppercase">
                          <span className="ml-1">
                            {item?.ducting_type?.product_type}
                          </span>
                          <span>{item?.ducting_type?.thickness}</span>
                          <span>{item?.ducting_type?.brand_type}</span>

                          <span>{item?.ducting_type?.joint_type}</span>
                        </span>
                      )}
                      <span
                        className={`${
                          item?.category_name === "Ducting" && "ml-1"
                        }`}
                      >
                        {" "}
                        {item?.name}
                      </span>
                    </div>
                  </th>
                  <td className="px-6 py-4">
                    {" "}
                    <div className="text-xs flex items-center ">
                      <span>{item?.description}</span>
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    $
                    {item?.category_name === "Ducting"
                      ? Number(item?.unit_price)?.toFixed(2)
                      : Number(item?.sell_price)?.toFixed(2)}
                  </td>
                  <td className="px-6 py-4">
                    {item?.new_quantity} {item?.unit}
                  </td>
                  {quotaionData?.is_item_discount && (
                    <td className="px-6 py-4">
                      $
                      {Number(
                        item?.item_discount > 0 ? item?.item_discount : 0
                      )?.toFixed(2)}
                    </td>
                  )}
                  <td className="px-6 py-4">
                    $
                    {calculateTotalPriceAfterDiscount(
                      quotaionData?.is_item_discount,
                      item
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>
        <section
          aria-labelledby="summary-heading"
          class="mt-5 rounded-md bg-white flex justify-end  mr-5"
        >
          <div>
            <div>
              <div>
                <dl class=" space-y-1 px-2 py-4">
                  <div class="flex items-center justify-between">
                    <dt class="text-sm text-gray-800">
                      Price ({quotaionData?.product?.length} item)
                    </dt>
                    <dd class="text-sm font-medium text-gray-900">
                      $ {quotaionData?.sub_total?.toFixed(2)}
                    </dd>
                  </div>
                  {quotaionData?.is_total_discount && (
                    <div class="flex items-center justify-between pt-4">
                      <dt class="flex items-center text-sm text-gray-800">
                        <span>Discount</span>
                      </dt>
                      <dd class="text-sm font-medium text-green-700">
                        $ {Number(quotaionData?.discount)?.toFixed(2)}
                      </dd>
                    </div>
                  )}

                  {quotaionData?.is_show_GST && (
                    <div class="flex items-center justify-between py-4">
                      <dt class="flex text-sm text-gray-800">
                        <span>GST</span>
                      </dt>
                      <dd class="text-sm font-medium text-green-700">
                        $ {quotaionData?.gst?.toFixed(2)}
                      </dd>
                    </div>
                  )}

                  <div class="flex items-center justify-between border-y border-dashed py-4 ">
                    <dt class="text-base font-medium text-gray-900">
                      Total Amount:
                    </dt>
                    <dd class="text-base font-medium text-gray-900">
                      $ {quotaionData?.grand_total?.toFixed(2)}
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </section>
      </div>
      {(quotaionData?.status === "Draft" ||
        quotaionData?.status === "Pending") && (
        <div>
          <button
            onClick={() => handleStatus(4)}
            className=" text-white tex-white border px-4 py-2 rounded mx-2 bg-red-400 hover:bg-red-600  "
          >
            Cancel
          </button>
          <button
            onClick={() => handleStatus(3)}
            className=" border px-4 py-2 rounded mx-2 bg-green-400 hover:bg-green-600 text-white  "
          >
            Approve
          </button>
        </div>
      )}
    </div>
  );
};

export default ViewPurchase;
