export const formatPageNumber = (currentPage, totalPages) => {
  const formattedCurrentPage = String(currentPage).padStart(2, "0");
  const formattedTotalPages = String(totalPages).padStart(2, "0");
  return ` ${formattedCurrentPage}/${formattedTotalPages}`;
};

export const calculateTotalPriceAfterDiscount = (isItemDiscount, item) => {
  const initialTotal =
    Number(
      item?.category_name === "Ducting" ? item?.unit_price : item?.sell_price
    ) * Number(item?.new_quantity);

  if (isItemDiscount && item?.item_discount) {
    const discountPercentage = Number(item.item_discount);
    const discountAmount = (initialTotal * discountPercentage) / 100;
    const totalAfterPercentageDiscount = initialTotal - discountAmount;
    return totalAfterPercentageDiscount?.toFixed(2);
  } else {
    return initialTotal.toFixed(2);
  }
};
