import React from "react";
// import "./print.css";
import dateFormat from "dateformat";
import capitalizeWordLetter from "../lib/capitalizeFirstLetter";
import { getMonthValue } from "../../lib/statusCode";

const PrintSOA = ({ details, componentRef, invoiceList }) => {
  const monthVal = getMonthValue(details?.to_date);
  const yearVal = details?.to_date?.split("-")[0];
  console.log("invoice List: ", invoiceList);

  const today = new Date();
  const formattedDate = today.toISOString().split("T")[0];

  // function formatPageNumber(currentPage, totalPages) {
  //   const formattedCurrentPage = String(currentPage).padStart(2, "0");
  //   const formattedTotalPages = String(totalPages).padStart(2, "0");

  //   return ` ${formattedCurrentPage}/${formattedTotalPages}`;
  // }

  // const totalItems = quotaionData?.product?.length || 0;
  // const itemsPerPage = 20;

  // const totalPages = Math.ceil(totalItems / itemsPerPage);
  // const now = new Date(quotaionData?.date ? quotaionData?.date : null);
  // const formattedDate = dateFormat(now, "dd-mm-yyyy");

  // function calculateTotalPriceAfterDiscount(isItemDiscount, item) {
  //   const initialTotal =
  //     Number(
  //       item?.category_name === "Ducting" ? item?.unit_price : item?.sell_price
  //     ) * Number(item?.new_quantity);

  //   if (isItemDiscount && item?.item_discount) {
  //     const discountPercentage = Number(item.item_discount);
  //     const discountAmount = (initialTotal * discountPercentage) / 100;
  //     const totalAfterPercentageDiscount = initialTotal - discountAmount;
  //     return totalAfterPercentageDiscount?.toFixed(2);
  //   } else {
  //     return initialTotal.toFixed(2);
  //   }
  // }

  const totalPages = 1;

  return (
    <div ref={componentRef} className="print-container h-full w-full">
      {Array(totalPages)
        .fill()
        .map((_, pageIndex) => {
          // const startItemIndex = pageIndex * itemsPerPage;
          // const endItemIndex = Math.min(
          //   startItemIndex + itemsPerPage,
          //   totalItems
          // );
          // const currentPageItems = items.slice(startItemIndex, endItemIndex);
          // const pageNumberDisplay = formatPageNumber(pageIndex + 1, totalPages);

          return (
            <div key={pageIndex} className="page-content">
              {/* <PageHeader
                quotaionData={quotaionData}
                formattedDate={formattedDate}
                pageNumberDisplay={pageNumberDisplay}
                capitalizeWordLetter={capitalizeWordLetter}
              /> */}
              <PageHeader
                month_val={monthVal}
                yearVal={yearVal}
                details={details}
                formattedDate={formattedDate}
              />

              {/* first table section */}
              <div>
                <div>
                  <p className="font-bold">
                    Payment Received and Outstanding from: {details?.from_date}{" "}
                    to
                    {details?.to_date}
                  </p>
                </div>
                <div>
                  <div class="overflow-x-auto">
                    <table class="min-w-full border border-black text-sm text-center">
                      <thead class="bg-gray-300">
                        <tr>
                          <th class="border border-black p-2">No</th>
                          <th class="border border-black p-2">Date</th>
                          <th class="border border-black p-2">
                            Invoice No & Description
                          </th>
                          <th class="border border-black p-2">Charges</th>
                          <th class="border border-black p-2">Credits</th>
                          <th class="border border-black p-2">Due Balance</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="border border-black p-2">1</td>
                          <td class="border border-black p-2">
                            Till {details?.to_date}
                          </td>
                          <td class="border border-black p-2 text-left">
                            Balance Debit & Credit
                          </td>
                          <td class="border border-black p-2">
                            $ {details?.total_grand_total}
                          </td>
                          <td class="border border-black p-2">
                            {details?.total_paid}
                          </td>
                          <td class="border border-black p-2">
                            {details?.total_due}
                          </td>
                        </tr>
                        {/* <tr>
                          <td class="border border-black p-2">2</td>
                          <td class="border border-black p-2">25/9/24</td>
                          <td class="border border-black p-2 text-left">
                            Payment received
                          </td>
                          <td class="border border-black p-2"></td>
                          <td class="border border-black p-2">$ {details?.total_paid}</td>
                          <td class="border border-black p-2"></td>
                        </tr> */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* second table section */}
              <div>
                <div class="overflow-x-auto mt-4">
                  <table class="min-w-full border border-black text-sm text-center">
                    {/* <!-- Header --> */}
                    <thead>
                      <tr class="bg-gray-300">
                        <th
                          colspan="9"
                          class="border border-black p-2 font-semibold"
                        >
                          Payment Received and Adjusted as per Invoice
                        </th>
                      </tr>
                      <tr class="bg-gray-300">
                        <th class="border border-black p-2">No</th>
                        <th class="border border-black p-2">Date</th>
                        <th class="border border-black p-2">Invoice No</th>
                        <th class="border border-black p-2">Charges</th>
                        <th class="border border-black p-2">Credits</th>
                        <th class="border border-black p-2">Due Balance</th>
                      </tr>
                    </thead>

                    {/* <!-- Body with a single row --> */}
                    <tbody>
                      {invoiceList &&
                        invoiceList.map((item, index) => (
                          <tr key={item.id}>
                            <td class="border border-black p-2">{index + 1}</td>
                            <td class="border border-black p-2">
                              {item?.date}
                            </td>
                            <td class="border border-black p-2 text-left">
                              {item?.ref}
                            </td>
                            <td class="border border-black p-2">
                              $ {item?.grand_total}
                            </td>
                            <td class="border border-black p-2">
                              $ {item?.paid_amount}
                            </td>
                            <td class="border border-black p-2">
                              $ {item?.due}
                            </td>
                          </tr>
                        ))}
                    </tbody>

                    {/* <!-- Footer for totals --> */}
                    <tfoot>
                      <tr class="bg-gray-100 font-semibold">
                        <td
                          colspan="3"
                          class="border border-black p-2 text-right"
                        >
                          Total Sale and received
                        </td>
                        <td class="border border-black p-2">
                          $ {details?.total_grand_total}
                        </td>
                        <td class="border border-black p-2">
                          $ {details?.total_paid}
                        </td>
                        <td class="border border-black p-2">
                          $ {details?.total_due}
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                  <p>
                    Note: Any discrepancy in this statement must be reported to
                    us in writing within 10 days
                  </p>
                </div>
              </div>
              {/* third table */}
              {/* <div>
                <div class="overflow-x-auto mt-4">
                  <table class="min-w-full border border-black text-sm text-center">
                    <thead>
                      <tr class="bg-gray-300">
                        <th class="border border-black p-2">Jun/24</th>
                        <th class="border border-black p-2">Jul/24</th>
                        <th class="border border-black p-2">Aug/24</th>
                        <th class="border border-black p-2">Sep/24</th>
                        <th class="border border-black p-2">Oct/24</th>
                        <th class="border border-black p-2 font-semibold">
                          Total Due:
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td class="border border-black p-2">$0.00</td>
                        <td class="border border-black p-2">$1,834.70</td>
                        <td class="border border-black p-2">$30.23</td>
                        <td class="border border-black p-2">$211.70</td>
                        <td class="border border-black p-2">$0.00</td>
                        <td class="border border-black p-2 font-semibold">
                          $2,076.63
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <p>
                    Note: Any discrepancy in this statement must be reported to
                    us in writing within 10 days
                  </p>
                </div>
              </div> */}
              {pageIndex < totalPages && <RepeatingSection />}
            </div>
          );
        })}
    </div>
  );
};

//   company address two lining
function addressFormating(add) {
  let splited = add.split(",");
  const firstPart = splited
    .slice(0, splited.length - 2)
    .join(",")
    .trim();
  const secondPart = splited
    .slice(splited.length - 2)
    .join(",")
    .trim();
  var addValue = (
    <>
      {/* <p class="text-md text-gray-800 mb-2 mt-0 pt-0">{firstPart}</p>
      <p class="text-md text-gray-800">{secondPart}</p> */}
      <p>{firstPart}</p>
      <p>{secondPart}</p>
    </>
  );
  return addValue;
}

const PageHeader = ({ month_val, yearVal, details, formattedDate }) => (
  <section className="page-header m-0 p-0">
    <div>
      <div>
        <div className="flex justify-center items-center">
          <div className="text-center flex justify-center">
            <div className="w-full px-4 py-3 sm:p-4">
              <h2 className="text-[22px] text-center text-green-600 font-bold leading-5">
                AMA M&E CONTRACTOR PTE LTD
              </h2>
              <div className="text-center mt-2">
                <dd className="ml-2 text-sm leading-3">
                  Company UEN/GST Registration No. 201700945E
                </dd>
              </div>
              <div className="text-center mt-2">
                <p className="ml-2 text-sm leading-5">
                  1 Tuas Bay Close #03-04, Liner, Singapore 636997
                </p>
              </div>
              <div className="text-center mt-2">
                <p className="ml-2 text-sm leading-3">
                  Tel: +65 6015 0254 HP: +65 8139 9772 E-mail:
                  amacontractors.sg@gmail.com
                </p>
              </div>
              <div>
                <h1 className="mt-3 text-base font-bold">
                  STATEMENT OF ACCOUNTS
                </h1>
                <h3 className="text-base font-bold">
                  Month Of {`${month_val}-${yearVal}`}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between w-full">
        <div className="flex items-center">
          <div className="pr-4">
            <div className="text-xl text-gray-900">
              <div className="min-w-[350px] border-gray-700">
                <p className="bg-gray-300 text-black text-md">
                  Customer Address:
                </p>
                <div>
                  <p className=" text-black text-md font-bold">
                    {details?.company_name}
                  </p>
                  <p>{details?.company_address}</p>
                  {/* <p>105 Sims Avenue,#05-11(Chancerlodge Complex)</p>
                  <p>Singapore 387429</p> */}
                </div>
              </div>

              <div className="flex">
                <p className="font-bold underline">Attention:</p>
                <div className="ml-2 flex-grow">
                  {/* <p>Mr. Abdul Malek</p>
                  <p>Mobile: No: +65 98839059</p>
                  <p>Email: slmengcon2017@gmail.com</p> */}
                  {details?.attention}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <p className="text-black text-md font-bold">Date: {formattedDate}</p>
          <p>Page: 01/01</p>
        </div>
      </div>
    </div>
  </section>
);

const RepeatingSection = () => (
  <section className="repeating-section">
    <div className="flex justify-between items-center px-4 mt-[-30px]">
      <div className="flex flex-col">
        <div className="flex justify-center">
          <img className="w-24" src="/signature.png" alt="" />
        </div>
        <h1 className="text-xs font-bold border-t-2 border-gray-950">
          Authorised Signature & Company Stamp
        </h1>
      </div>
      <div>
        <div className="flex justify-between space-x-4 items-center">
          <div className="text-xs font-bold">
            <div className="py-2 mt-1 flex justify-between items-center pr-5 space-x-10">
              <div>
                <img className="w-24 mb-1" src="/uaf.jpg" alt="" />
                <img className="w-20" src="/biz.jpg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default PrintSOA;
