import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { BsPlus } from "react-icons/bs";
import { BiMinus } from "react-icons/bi";
import { AiOutlineEye } from "react-icons/ai";
import DeleteAlert from "../Shared/DeleteAlert";
import axios from "axios";
import { useHeader } from "../../lib/headers";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import Alert from "../Shared/Alert";
const Inventory = () => {
  const [products, setProducts] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [current, setCurrent] = useState(1);
  const [allInventory, setAllInventory] = useState(null);
  const itemsPerPage = 25;
  const headers = useHeader();

  const fetchData = () => {
    axios
      .get(
        `${process.env.REACT_APP_ROOT_URL}/api/product/all/?page=${current}&search=${searchText}`,
        headers
      )
      .then((response) => {
        setAllInventory(response.data);
        // Handle the response data
        const data = response.data?.results;
        setProducts(data);
      })
      .catch((error) => {
        // Handle any errors
        console.error(error);
      });
  };
  useEffect(() => {
    fetchData();
  }, [current, searchText]);

  const onChange = (page) => {
    // Calculate the new data range based on the page
    // const startIndex = (page - 1) * itemsPerPage;
    // const endIndex = startIndex + itemsPerPage;
    // Update the data to be displayed on the current page
    // setAllInventory(allInventory.results.slice(startIndex, endIndex));
    setCurrent(page);
  };
  const handleIsMultiInventory = async (e, item) => {
    // console.log("item", item);

    await axios
      .patch(
        `${process.env.REACT_APP_ROOT_URL}/api/product/${item?.id}/`,
        {
          has_multiple_inventory: e?.target?.checked,
        },
        headers
      )
      .then((response) => {
        Alert("Product updated successfully", "success");
        fetchData();
        console.log("response", response.data);
      })
      .catch((error) => {});
  };

  // ===== ================= csv download ======================
  const downloadCSV = async () => {
    try {
      const response = await fetch(
        "https://api.amamne.com.sg/api/export-inventory-csv/",
        // "http://localhost:8000/api/export_inventory_csv/",
        {
          headers: {
            Accept: "text/csv", // Requesting CSV format
          },
        }
      );

      // // Check if the response is ok (status 200)
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const blob = await response.blob();
      console.log("blog response: ", blob);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "inventoryReport.csv");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    console.log("response: ", response)
    } catch (error) {
      console.error("Error downloading the CSV file:", error);
    }
  };

  return (
    <div>
        <div className="flex items-center justify-between mb-3">
            <h1 className=" mb-4 text-xl font-bold">Inventory</h1>
            <button
                    onClick={downloadCSV}
                    className=" bg-gray-900 text-white px-4 py-2 rounded-lg my-2"
                >
                    Export Inventory CSV
            </button>
        </div>
      
      <div class="mb-3">
        <input
          onChange={(e) => setSearchText(e?.target?.value)}
          type="search"
          class="relative m-0 h-10 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-primary focus:text-neutral-700 focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none    "
          id="exampleSearch"
          placeholder="Type query"
        />
      </div>
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg ">
        <table class="w-full text-sm text-left text-gray-500 ">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50 ">
            <tr>
              <th scope="col" class="px-6 py-3">
                Product name
              </th>
              <th scope="col" class="px-6 py-3">
                Sell Price
              </th>
              <th scope="col" class="px-6 py-3">
                category
              </th>

              <th scope="col" class="px-6 py-3">
                Quantity
              </th>
              {/* <th scope="col" class="px-6 py-3">
                Has Multi Inventory
              </th> */}
              <th scope="col" class="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {products?.length > 0 &&
              products?.map((item) => (
                <tr
                  className="bg-white border-b dark::bg-gray-800 "
                  key={item.id}
                >
                  <th
                    scope="row"
                    className="px-6 py-1 font-medium text-gray-900 whitespace-nowrap "
                  >
                    <p>{item?.name}</p>
                    <div>
                      {item?.attributes?.length > 0 &&
                        item.attributes.map((i) => (
                          <div
                            className="text-gray-300 text-xs flex"
                            key={i.attribute}
                          >
                            <span>{i.attribute}</span> :<span>{i.value}</span>
                          </div>
                        ))}
                    </div>
                  </th>
                  <td className="px-6 py-4">{item?.sell_price}</td>
                  <td className="px-6 py-4">{item?.category_name}</td>

                  <td className="px-6 py-4">
                    {item?.quantity ? (
                      <span>
                        {item?.quantity} {item?.unit}
                      </span>
                    ) : (
                      "N/A"
                    )}
                  </td>
                  {/* <td className="px-6 py-4">
                    <label class="inline-flex items-center cursor-pointer">
                      <input
                        onChange={(e) => handleIsMultiInventory(e, item)}
                        type="checkbox"
                        defaultChecked={item?.has_multiple_inventory}
                        class="sr-only peer"
                      />
                      <div class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark::peer-focus:ring-blue-800 rounded-full peer dark::bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark::border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                  </td> */}
                  <td className="px-6 py-4 w-20">
                    <div className="flex">
                      <Link
                        to={`/inventory/${item?.id}`}
                        className="mx-1 py-2 bg-sky-700 text-white px-2 rounded-full"
                      >
                        <AiOutlineEye size={20} />
                      </Link>
                      {/* <Link
                        to={`${
                          item?.has_multiple_inventory
                            ? `/multi-inventory/${item?.id}?product_name=${item?.name}`
                            : `/inventory/${item?.id}`
                        }`}
                        className="mx-1 py-2 bg-sky-700 text-white px-2 rounded-full"
                      >
                        <AiOutlineEye size={20} />
                      </Link> */}
                      {/* <button
                      onClick={() => handleDelete(item?.id)}
                      className="mx-1 py-2 bg-red-600 text-white px-2 rounded-full"
                    >
                      <BiMinus size={20} />
                    </button> */}
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div className=" sticky bottom-0  flex justify-center items-center my-5 bg-white py-4 shadow ">
        <Pagination
          onChange={onChange}
          current={current}
          pageSize={itemsPerPage}
          total={allInventory?.count}
        />
      </div>
    </div>
  );
};

export default Inventory;
