import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CiBoxList } from "react-icons/ci";
import axios from "axios";

const AddCompany = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    contact_person: "",
    name: "",
    phone: "",
    email: "",
    address: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ROOT_URL}/api/company/`,
        formData,
        { headers }
      );
      navigate("/company");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className=" flex justify-center items-center h-screen my-10 md:my-0">
      <form onSubmit={handleSubmit} class="w-full ">
        <div className=" flex justify-between mb-10 max-w-2xl mx-auto">
          <h1 class=" text-3xl  text-gray-500 font-bold ">Add Company</h1>

          <Link to="/company">
            <CiBoxList size={30} />
          </Link>
        </div>
        <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label
              class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
              for="inline-full-name"
            >
              Company Name
            </label>
          </div>
          <div class="md:w-1/2">
            <input
              name="name"
              onChange={handleChange}
              class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="inline-full-name"
              type="text"
              placeholder=" Enter Company Name"
            />
          </div>
        </div>
        <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label
              class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
              for="inline-password"
            >
              Contact Person Name
            </label>
          </div>
          <div class=" md:w-1/2 ">
            <input
              name="contact_person"
              onChange={handleChange}
              class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="inline-password"
              type="text"
              placeholder="Enter Owner Name"
            />
          </div>
        </div>
        <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label
              class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
              for="phone"
            >
              Phone
            </label>
          </div>
          <div class=" md:w-1/2 ">
            <input
              name="phone"
              onChange={handleChange}
              class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="phone"
              type="text"
              placeholder="EnterYour Phone Number"
            />
          </div>
        </div>
        <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label
              class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
              for="Email"
            >
              Email
            </label>
          </div>
          <div class=" md:w-1/2 ">
            <input
              name="email"
              onChange={handleChange}
              class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="Email"
              type="text"
              placeholder="Enter Your Email"
            />
          </div>
        </div>
        <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label
              class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
              for="Address"
            >
              Address
            </label>
          </div>
          <div class=" md:w-1/2 ">
            {/* <input
              name="address"
              onChange={handleChange}
              class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              id="Address"
              type="text"
              placeholder="Enter Your Address"
            /> */}
            <textarea
              onChange={handleChange}
              name="address"
              id="Address"
              rows="8"
              class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              placeholder="Write your address here..."
            ></textarea>
          </div>
        </div>
        <div class="md:flex md:items-center">
          <div class="md:w-1/3"></div>
          <div class="md:w-2/3">
            <button
              onClick={() => navigate(-1)}
              class=" w-24 bg-red-100 text-red-600 shadowfocus:shadow-outline focus:outline-none  font-bold py-2 px-4 rounded"
              type="button"
            >
              Back
            </button>
            <button
              class="mx-4 w-24 bg-gray-950 text-white shadowfocus:shadow-outline focus:outline-none  font-bold py-2 px-4 rounded"
              type="submit"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddCompany;
