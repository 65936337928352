const CompanyInfo = ({ title = "Sample" }) => {
  return (
    <div>
      <div className="flex justify-center items-center pb-6">
        <div className="text-center flex justify-center">
          <div className="w-full px-4 ">
            <h2 className="text-[22px] text-center text-green-600 font-bold leading-5">
              AMA M&E CONTRACTOR PTE LTD
            </h2>
            <div className="text-center mt-2">
              <dd className="ml-2 text-sm leading-3">
                Company UEN/GST Registration No. 201700945E
              </dd>
            </div>
            <div className="text-center mt-2">
              <p className="ml-2 text-sm leading-5">
                1 Tuas Bay Close #03-04, Liner, Singapore 636997
              </p>
            </div>
            <div className="text-center mt-2">
              <p className="ml-2 text-sm leading-3">
                Tel: +65 6015 0254 HP: +65 8139 9772 E-mail:
                amacontractors.sg@gmail.com
              </p>
            </div>
            <div>
              <h1 className="mt-2 text-base font-bold underline">{title}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyInfo;
