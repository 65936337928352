import "./print.css";
import ParentPdf from "../PdfComp/ParentPdf";
import { formatPageNumber } from "../../lib/pdfUtils";

const tableHeader = [
  { header: "SL", field: "sl" },
  { header: "Item", field: "item" },
  { header: "Size", field: "size" },
  { header: "Unit", field: "unit" },
  { header: "Qty", field: "quantity" },
];

const NewPrintDO = ({ items, componentRef, deliveryOrder }) => {
  const totalItems = deliveryOrder?.product?.length || 0;

  let itemsPerPage = 20;
  if (deliveryOrder.site_address) {
    itemsPerPage = 17;
  }
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  return (
    <>
      <div ref={componentRef} className="print-container h-full w-full">
        {Array(totalPages)
          .fill()
          .map((_, pageIndex) => {
            const startItemIndex = pageIndex * itemsPerPage;
            const endItemIndex = Math.min(
              startItemIndex + itemsPerPage,
              totalItems
            );
            const currentPageItems = items.slice(startItemIndex, endItemIndex);
            const pageNumberDisplay = formatPageNumber(
              pageIndex + 1,
              totalPages
            );
            const formattedData =
              currentPageItems.length &&
              currentPageItems.map((item, index) => ({
                ...item, // giving all data for later use
                sl: (
                  <div className="text-center">
                    {pageIndex * itemsPerPage + index + 1}
                  </div>
                ),
                item: (
                  <div className="w-full max-w-[200px] font-normal text-dark text-xs flex flex-wrap overflow-hidden break-words">
                    <div className="text-dark text-xs flex flex-wrap">
                      {item?.category_name === "Ducting" && (
                        <span className="space-x-1 uppercase flex flex-wrap">
                          <span className="ml-1">
                            {item?.ducting_type?.product_type}
                          </span>
                          <span>{item?.ducting_type?.thickness}</span>
                          <span>{item?.ducting_type?.brand_type}</span>
                          <span>{item?.ducting_type?.joint_type}</span>
                        </span>
                      )}
                      <span
                        className={`${
                          item?.category_name === "Ducting" ? "ml-1" : ""
                        } overflow-hidden break-words`}
                      >
                        {item?.parent_name || "\u00A0"}
                      </span>
                    </div>
                  </div>
                ),
                size: (
                  <div className="text-xs flex justify-center w-full max-w-[200px] overflow-hidden break-words">
                    <h1 className="whitespace-normal">
                      {item?.category_name === "Ducting"
                        ? item?.display_dimensions
                        : item?.parent
                        ? Object.values(item?.variant || {}).join("/ ")
                        : item?.description || "\u00A0"}
                    </h1>
                  </div>
                ),
                unit: (
                  <div className="w-full min-w-[50px] text-center">
                    {item?.unit || "\u00A0"}
                  </div>
                ),
                quantity: (
                  <div className="text-xs w-full overflow-hidden break-words text-center">
                    {item?.new_quantity || "\u00A0"}
                  </div>
                ),
              }));

            return (
              <div key={pageIndex} className="page-content">
                <ParentPdf
                  title={"Delivery Order"}
                  pageNumber={pageNumberDisplay}
                  tableHeader={tableHeader}
                  tableData={formattedData}
                  doFooterData={items}
                  data={deliveryOrder}
                  footerShow={"do"}
                  customerInfoFor={"do"}
                  totalPages={totalPages}
                  pageIndex={pageIndex}
                />
              </div>
            );
          })}
      </div>
    </>
  );
};

export default NewPrintDO;
