const TableFooter = ({
  footerShow,
  doFooterData,
  quotationFooterData,
  invoiceFooterData,
}) => {
  return (
    <>
      {/* -------delivery order section footer---------------- */}
      {footerShow === "do" && (
        <tr className="bg-white border border-gray-950">
          <th
            colSpan={4}
            className="text-gray-950 border-r border-gray-950 text-right text-base font-medium"
          >
            Total Qty:
          </th>
          <th className="text-gray-950 text-base font-medium text-center">
            {doFooterData?.reduce(
              (acc, item) => acc + parseInt(item.new_quantity),
              0
            )}
          </th>
        </tr>
      )}

      {/* -------invoice section footer---------------- */}
      {footerShow === "invoice" && (
        <>
          <tr className="bg-white border border-gray-950">
            <th
              colSpan={invoiceFooterData?.is_item_discount ? 7 : 6}
              className="text-gray-950 border-r border-gray-950 text-right text-base"
            >
              Total Value Before GST:
            </th>
            <th className="px-6 py-1 border-r border-gray-950 text-right pr-4 border-b">
              <span>$</span>
              <span>{Number(invoiceFooterData?.sub_total)?.toFixed(2)}</span>
            </th>
          </tr>
          {invoiceFooterData?.is_show_GST && (
            <tr className="bg-white border-r border-gray-950">
              <th
                colSpan={invoiceFooterData?.is_item_discount ? 7 : 6}
                className="text-gray-950 border-r border-gray-950 text-right text-base font-medium"
              >
                Add 9% GST:
              </th>
              <th className="px-6 py-1 border-r border-gray-950 text-right pr-4 border-b">
                <span>$</span>
                <span>{Number(invoiceFooterData?.gst)?.toFixed(2)}</span>
              </th>
            </tr>
          )}
          <tr className="bg-white border-r border-gray-950">
            <th
              colSpan={invoiceFooterData?.is_item_discount ? 7 : 6}
              className="text-gray-950 border-r border-gray-950 text-right text-base font-medium"
            >
              Total Value{" "}
              {invoiceFooterData?.is_show_GST && <span>With GST</span>}:
            </th>
            <th className="px-6 py-1 border-r border-gray-950 text-right pr-4 border-b">
              <span>$</span>
              <span>{Number(invoiceFooterData?.grand_total)?.toFixed(2)}</span>
            </th>
          </tr>
        </>
      )}

      {/* -------quotation section footer---------------- */}
      {footerShow === "quotation" && (
        <>
          <tr className="bg-white border border-gray-950">
            <th
              colSpan={quotationFooterData?.is_item_discount ? 7 : 6}
              className="text-gray-950 border-r border-gray-950 text-right text-base"
            >
              Total Value Before GST:
            </th>
            <th className="px-6 py-1 border-r border-gray-950 text-right pr-3 border-b">
              <span>$</span>
              <span>{Number(quotationFooterData?.sub_total)?.toFixed(2)}</span>
            </th>
          </tr>
          {quotationFooterData?.is_show_GST && (
            <tr className="bg-white border-r border-gray-950">
              <th
                colSpan={quotationFooterData?.is_item_discount ? 7 : 6}
                className="text-gray-950 border-r border-gray-950 text-right text-base font-medium"
              >
                Add 9% GST:
              </th>
              <th className="px-6 py-1 border-r border-gray-950 text-right pr-3 border-b">
                <span>$</span>
                <span>{Number(quotationFooterData?.gst)?.toFixed(2)}</span>
              </th>
            </tr>
          )}
          <tr className="bg-white border-r border-gray-950">
            <th
              colSpan={quotationFooterData?.is_item_discount ? 7 : 6}
              className="text-gray-950 border-r border-gray-950 text-right text-base font-medium"
            >
              Total Value{" "}
              {quotationFooterData?.is_show_GST && <span>With GST</span>}:
            </th>
            <th className="px-6 py-1 border-r border-gray-950 text-right pr-3 border-b">
              <span>$</span>
              <span>
                {Number(quotationFooterData?.grand_total)?.toFixed(2)}
              </span>
            </th>
          </tr>
        </>
      )}
    </>
  );
};

export default TableFooter;
